.corp-memb {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__form-search {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 40px;
    height: 50px;
    margin-bottom: 40px;
    @include sm-block {
      grid-template-columns: 1fr;
      height: auto;
      grid-row-gap: 40px;
      margin-bottom: 0;
    }
  }
  .btn-blue {
    background: #014A94;
    border-radius: 67px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 12px;
    @include sm-block {
      height: 50px;
    }
  }
  &__input-search {
    height: 100%;
    width: 100%;
    border: 1px solid #5F91C5;
    border-radius: 6px;
    padding: 19px 55px 19px 15px;
    &::placeholder {
      font-size: 14px;
    }
  }
  &__wrap-input-search {
    position: relative;
  }
  .i-cross {
    height: 12px;
    width: 12px;
    background: url( /static/images/pages/corp-memb/corp-memb/svg/cross.svg ) no-repeat center center;
    top: 22px;
    right: 23px;
    position: absolute;
    cursor: pointer;
  }
  &__wrap-card {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 40px;
    padding: 40px 23px;
    border-radius: 6px;
    &:nth-child(even) {
      background: #E0E0E0;
    }
    @include  lg-block {
      align-items: center;
    }
    @include  sm-block {
      display: block;
    }
  }
  &__card-title {
    font-size: 18px;
    margin-bottom: 25px;
    color: #000000;
    text-decoration: underline;
  }
  &__logo {
    grid-row: 1/4;
    margin-top: 50px;
    @include  lg-block {
      grid-row: 1;
      margin-top: 0;
      margin-bottom: 25px;
    }
    @include sm-block {
      grid-row: 1/4;
    }
    @include xs-block {
      max-width: 100%;
    }
  }
  &__descr {
    font-size: 16px;
    @include  lg-block {
      grid-column: 1/3;
    }
  }
  &__wrap-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    @include  lg-block {
      grid-column: 1/3;
    }
    @include sm-block {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 20px;
    }
    @include xs-block {
      grid-template-columns: 1fr;
    }
  }
  &__link {
    color: #014A94;
    text-decoration-line: underline;
  }
  &__head-city, &__head-site, &__head-name {
    font-size: 14px;

  }
  &__head-city {
    @include sm-block {
      order: 1;
    }
  }
  &__head-site {
    @include sm-block {
      order: 3;
    }
  }
  &__head-name {
    @include sm-block {
      order: 5;
    }
  }
  &__info-city {
    @include sm-block {
      order: 2;
    }
  }
  &__info-site {
    @include sm-block {
      order: 4;
    }
  }
  &__info-name {
    @include sm-block {
      order: 6;
    }
  }
  &__wrap-pagination {
    position: relative;
    margin-top: 35px;
    @include sm-block {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  .btn-arrow {
    border: 1px solid #A7AABC;
    height: 30px;
    width: 30px;
    display: grid;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 3px;
    &.act {
      background: #014A94;
      border: 1px solid #014A94;
    }
    &.prev {
      margin-right: 60px;
      @include sm-block {
        margin-right: 30px;
      }
      &:after {
        content: '';
        width: 6px;
        height: 6px;
        display: block;
        transform-origin: center center;
        transform: translateY(0%) rotate(135deg);
        border-bottom: solid 2px #A7AABC;
        border-right: solid 2px #A7AABC;
      }
    }
    &.next {
      margin-left: 60px;
      @include sm-block {
        margin-left: 30px;
      }
      &:after {
        content: '';
        width: 6px;
        height: 6px;
        display: block;
        transform-origin: center center;
        transform: translateY(0%) rotate(-45deg);
        border-bottom: solid 2px #A7AABC;
        border-right: solid 2px #A7AABC;
      }
    }
    &.act {
      background: #014A94;
      border: 1px solid #014A94;
      &:after {
        border-bottom: solid 2px #FFFFFF;
        border-right: solid 2px #FFFFFF;
      }
    }
  }
  .page {
    color: #A7AABC;
    position: relative;
    font-weight: 700;
    font-size: 14px;
    &.act {
      color: #014A94;
      &:after {
        content: '';
        background-color: #014A94;
        height: 2px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 5px;
      }
    }
  }
  &__pagination {
    position: absolute;
    top: 0;
    right: calc(50% - 150px );
    display: flex;
    @include lg-block {
      right: 0;
    }
    @include sm-block {
      position: static;
      order: 1;
      margin-bottom: 40px ;
    }
  }
  &__wrap-page {
    display: flex;
    width: 120px;
    justify-content: space-between;
    margin-top: 5px;
  }
  .important-link {
    text-transform: none;
    @include sm-block {
      order: 2;
    }
  }
}