/* roboto-300 - latin_cyrillic */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: url('roboto-v20-latin_cyrillic-300.eot'); /* IE9 Compat Modes */
	src: local('Roboto Light'), local('Roboto-Light'),
	url('roboto-v20-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('roboto-v20-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
	url('roboto-v20-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
	url('roboto-v20-latin_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
	url('roboto-v20-latin_cyrillic-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - cyrillic_latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('roboto-v20-cyrillic_latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Roboto'), local('Roboto-Regular'),
	url('roboto-v20-cyrillic_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('roboto-v20-cyrillic_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('roboto-v20-cyrillic_latin-regular.woff') format('woff'), /* Modern Browsers */
	url('roboto-v20-cyrillic_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('roboto-v20-cyrillic_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin_cyrillic */
@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	src: url('roboto-v20-latin_cyrillic-italic.eot'); /* IE9 Compat Modes */
	src: local('Roboto Italic'), local('Roboto-Italic'),
	url('roboto-v20-latin_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('roboto-v20-latin_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
	url('roboto-v20-latin_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
	url('roboto-v20-latin_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
	url('roboto-v20-latin_cyrillic-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - cyrillic_latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: url('roboto-v20-cyrillic_latin-500.eot'); /* IE9 Compat Modes */
	src: local('Roboto Medium'), local('Roboto-Medium'),
	url('roboto-v20-cyrillic_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('roboto-v20-cyrillic_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
	url('roboto-v20-cyrillic_latin-500.woff') format('woff'), /* Modern Browsers */
	url('roboto-v20-cyrillic_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
	url('roboto-v20-cyrillic_latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500italic - latin_cyrillic */
@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 500;
	src: url('roboto-v20-latin_cyrillic-500italic.eot'); /* IE9 Compat Modes */
	src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
	url('roboto-v20-latin_cyrillic-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('roboto-v20-latin_cyrillic-500italic.woff2') format('woff2'), /* Super Modern Browsers */
	url('roboto-v20-latin_cyrillic-500italic.woff') format('woff'), /* Modern Browsers */
	url('roboto-v20-latin_cyrillic-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
	url('roboto-v20-latin_cyrillic-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - cyrillic_latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: url('roboto-v20-cyrillic_latin-700.eot'); /* IE9 Compat Modes */
	src: local('Roboto Bold'), local('Roboto-Bold'),
	url('roboto-v20-cyrillic_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('roboto-v20-cyrillic_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('roboto-v20-cyrillic_latin-700.woff') format('woff'), /* Modern Browsers */
	url('roboto-v20-cyrillic_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('roboto-v20-cyrillic_latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700italic - latin_cyrillic */
@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 700;
	src: url('roboto-v20-latin_cyrillic-700italic.eot'); /* IE9 Compat Modes */
	src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
	url('roboto-v20-latin_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('roboto-v20-latin_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
	url('roboto-v20-latin_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
	url('roboto-v20-latin_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
	url('roboto-v20-latin_cyrillic-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - cyrillic_latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	src: url('roboto-v20-cyrillic_latin-900.eot'); /* IE9 Compat Modes */
	src: local('Roboto Black'), local('Roboto-Black'),
	url('roboto-v20-cyrillic_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('roboto-v20-cyrillic_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
	url('roboto-v20-cyrillic_latin-900.woff') format('woff'), /* Modern Browsers */
	url('roboto-v20-cyrillic_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
	url('roboto-v20-cyrillic_latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}