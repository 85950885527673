.right-bar {
  background-color: #014A94;
  background-position: 10px center;
  background-repeat: no-repeat;
  padding: 8px 15px 8px rem(60px);
  border-radius: 6px;
  width: rem(245px);
  position: fixed;
  right: -185px;
  top: auto;
  height: rem(60px);
  transition: all 0.5s;
  z-index: 30;
  opacity: 1;
  border: 1px solid #ffffff;
  color: #ffffff;
  &:hover {
    right: 0;
    @include md-block {
      right: -185px;
    }
  }
  @include md-block {
    top: 30%;

  }
  &.is-static {
    position: absolute;
    bottom: 50vh;
    top: auto;
    @include md-block {
      opacity: 0;
      top: 30%;
      right: -245px;
      position: fixed;
    }
  }
}