.specialist {
  display: block;
  position: relative;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    font-size: 14px;
  }

  .container-search {
    .search-top {
      margin-bottom: rem(40px);
      &__title {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.25px;
        margin-bottom: rem(20px);
      }
      &__container {
        display: grid;
        grid-template-columns: rem(315px) rem(315px);
        grid-column-gap: rem(40px);
        grid-row-gap: rem(25px);
        margin-bottom: rem(25px);
        margin-left: 0;
        @include  sm-block {
          grid-template-columns: auto;
          width: 100%;
        }
      }
      &__input {
        border: 1px solid #A7AABC;
        border-radius: 6px;
        padding:  17px 17px 11px;
        &::placeholder {
          color: #737687;
        }
      }
      &__select {
        border: 1px solid #A7AABC;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 17px 17px 10px;
        width: 100%;
        margin-bottom: 10px;
        &::placeholder {
          color: #737687;
        }
      }
      &__select {
        background: transparent;
      }
      &__container-select {
        position: relative;
        border: 1px solid #A7AABC;
        border-radius: 6px;
        @include  lg-block {
          margin-bottom: rem(10px);
        }
      }
      &__container-select select {
        display: none;
      }
      .select-selected {
        border: 1px solid #A7AABC;
        background-color: #ffffff;
        @include  lg-block {
          height: 100%;
        }
      }

      .select-selected:after {
        position: absolute;
        content: "";
        top: 25px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid #737687;
        border-color: #737687 transparent transparent transparent;
      }


      .select-selected.select-arrow-active:after {
        border-color: transparent transparent #737687 transparent;
        top: 19px;
      }


      .select-items div,.select-selected {
        color: #737687;
        padding: 17px 17px 10px;
        border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
        cursor: pointer;
        border-radius: 6px;
        user-select: none;
      }


      .select-items {
        position: absolute;
        background-color: #ffffff;
        border: 1px solid #737687;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        border-radius: 6px;
      }

      .select-hide {
        display: none;
      }

      .select-items div:hover, .same-as-selected {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .search-main {
      padding: rem(40px) 0;
      border-top: 1px solid #A7AABC;
      border-bottom: 1px solid #A7AABC;
      display: grid;
      grid-template-columns: 2fr 3fr 3fr;
      grid-column-gap: rem(40px);
      grid-row-gap: rem(20px);
      margin-bottom: rem(100px);
      @include sm-block {
        grid-template-columns: auto;
      }
      .space{
        @include sm-block {
          display: none;
        }
      }
    }
    .btn-hollow {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.75px;
      padding: rem(21px) 0 rem(17px);
      border-radius: 67px;
      background: transparent;
      color: #000000;
      display: inline-block;
      text-align: center;
      &.yellow {
        border: 1px solid #FE842B;
        width: rem(315px);
        @include sm-block {
          width: 100%;
        }
        &:hover {
          color: #ffffff;
          background: #FE842B;
        }
      }
      &.blue {
        border: 1px solid #014A94;
        border-radius: 67px;
        &:hover {
          color: #ffffff;
          background: #014A94;
        }

      }
      &.small {
        padding: 13px 25px ;
        @include sm-block {
          padding: rem(21px) 0 rem(17px);
        }
      }
    }
  }

  .container-info {


    .container-table-scroll {
      margin-bottom: 40px;
    }
    &__title {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.25px;
      margin-bottom: rem(20px);
    }
    table {
      background-color: #F2F2F2;
      width: 100%;
      text-align: center;
      padding: 17px 20px;
      border-spacing: 0;
      border-bottom: 9px solid #014A94;
      border-radius: 6px;
      @include sm-block {
        border-bottom: 0;
      }
    }
    th {
      font-weight: 700;
      border: 1px solid #A7AABC;
      border-top: none;
      padding: 18px 10px;
      &:first-child{
        border-left: none;
      }
      &:last-child{
        border-right: none;
      }
    }
    td {
      border: 1px solid #A7AABC;
      padding: 18px 10px;
      &:first-child{
        border-left: none;
      }
      &:last-child{
        border-right: none;
      }
    }
    tr:last-child {
      td {
        border-bottom: none;
        &:first-child{
          border-left: none;
        }
        &:last-child{
          border-right: none;
        }
      }
    }
  }
}
