.history {
  display: block;
  position: relative;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  @include sm-block {
    padding-left: 60px;
  }
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    font-size: 14px;
  }
  .section {
    margin-bottom: rem(50px);
  }
  .h4 {
    margin-bottom: rem(50px);
  }
  .circle {
    overflow: hidden;
    position: relative;
  }
  .timer {
    width: 300px;
    height: 400px;
    background: url(/static/images/pages/history/history/elips.png) no-repeat scroll center center;
    background-size: contain;
    -moz-transition: all 0.4s 0.04s ease;
    -o-transition: all 0.4s 0.04s ease;
    -webkit-transition: all 0.4s 0.04s ease;
    margin-left: -150px;
    position: relative;
    @include xs-block {
      width: 250px;
    }
  }
  .date-circle {
    position: absolute;
    color: #5F91C5;
    font-size: 18px;
    transform: rotate(-55deg);
  }
  .date-1 {
    right: 86px;
    top: 34px;
    transform: rotate(-53deg);
    width: rem(60px);
    //display: none;
    @include  lg-block {
      display: none;
    }
  }
  .date-2 {
    top: 86px;
    right: 39px;
    transform: rotate(-32deg);
    width: rem(60px);
    //display: none;
    @include  lg-block {
      display: none;
    }
  }
  .date-3 {
    top: 289px;
    right: 39px;
    transform: rotate(32deg);
    width: rem(60px);
    //display: none;
    @include  lg-block {
      display: none;
    }
  }
  .date-4 {
    right: 86px;
    top: 341px;
    transform: rotate(53deg);
    width: rem(60px);
    //display: none;
    @include  lg-block {
      display: none;
    }
  }
  .gr {
    background: url(/static/images/pages/history/history/gr.png) no-repeat scroll center center;
    position: absolute;
    top: 0;
    left: 85px;
    width: 100px;
    height: 400px;
    @include xs-block {
      left: 75px;
      width: 60px;
    }
  }
  .story {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-column-gap: rem(40px);
    &__description {
      font-size: 16px;
      color: #000;
      @include xs-block {
        font-size: 12px;
      }
    }
    @include xs-block {
      display: block;
    }
  }
  .date {
    color: #000;
    &__span-year {
      display: block;
      font-size: 24px;
      font-weight: 300;
      @include md-block {
        display: inline-block;
      }
    }
    &__span-month {
      font-weight: 300;
    }
    @include xs-block {
      display: grid;
      margin-bottom: 20px;
    }
  }
  .current-date {
    position: absolute;
    top: 170px;
    left: -200px;
    width: 300px;
    text-align: right;
    line-height: 1.2;
    &__year {
      font-weight: 500;
      font-size: 32px;
      padding-right: 30px;
      color: #014A94;
      display: block;
      @include xs-block {
        font-size: 22px;
        padding-right: 48px;
      }
    }
    &__month {
      font-weight: 500;
      font-size: 16px;
      padding-left: rem(205px);
      display: block;
      letter-spacing: 0.15px;
      text-align: left;
      color: #014A94;
      @include xs-block {
        font-size: 10px;
      }
    }
     p {
      color: #014A94;
      font-size: 40px;
      line-height: 300px;
    }
  }
  .story {
    padding: 25px 20px 35px;
    color: #ccc;
    -moz-transition: all 0.4s 0.04s ease;
    -o-transition: all 0.4s 0.04s ease;
    -webkit-transition: all 0.4s 0.04s ease;
    margin-bottom: 30px;
    border-radius: 6px;
    opacity: .5;
    @include xs-block {
      padding: 0;
    }
    .date {
      color: #5F91C5;
      position: relative;
      padding-left: 20px;
      &:before {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: #5F91C5;
        left: 0;
        top: 13px;
        border-radius: 50%;
      }
    }
    &__description {
      color: #5F91C5;
    }
    &.is-active {
        color: #000;
        border-left: none;
        background-color: #ffffff;
        opacity: 1;

      .date:before {
        background-color: #000;
      }
      & .date__span-year {
        font-weight: 500;
        color: #000;
      }
      & .date__span-month {
        color: #000;
      }
      & .story__description {
        color: #333333;
      }
      & .store__li-point:before {
        background-color: #000000;
      }

    }
    @include md-block {
      grid-template-columns: auto;
      grid-row-gap: 20px;

    }
    &__span-paragraph {
      display: block;
    }
    &__ol-list {

    }
    &__li-point {
      margin-bottom: 10px;
      position: relative;
      padding-left: 15px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        background-color: #000000;
        border-radius: 50%;
        width: 5px;
        height: 5px;
      }
    }
  }
  .container-years {
    display: grid;
    grid-template-columns: 1fr 3fr;
    height: 500px;
    position: relative;
    margin-top: 100px;
    @include sm-block {
      grid-template-columns: 1fr 1fr;
      margin-top: 50px;
    }
    @include xs-block {
      grid-column-gap: 20px;
    }

  }
  .container-descriptions {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

  }
  .story:first-child {
    margin-top: 190px;
  }
  .story:last-child {
    margin-bottom: 235px;
  }

}