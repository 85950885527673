.stage-cert {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  margin-top: rem(80px);
  font-size: rem(16px);
  &__title {
    margin-bottom: rem(40px);
  }
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include md-block {
      margin: auto;
    }
    font-size: 14px;
  }
  &__table {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
    @include sm-block {
      display: none;
    }
  }
  &__table-mobile {
    display: none;
    width: 100%;
    @include sm-block {
      display: block;
    }
  }
  .btn-d {
    border: 1px solid #5F91C5;
  }
  .btn-c {
    border: 1px solid #7D60A8;
  }
  .btn-b {
    border: 1px solid #014A94;
  }
  .btn-a {
    border: 1px solid #FE842B;
  }
  &__wrapper-point.border-visible {
    border-color: #737687;
  }

  &__wrapper-point {
    padding: 8px;
    border: 1px solid transparent;
    border-radius: 6px;
    margin-bottom: 10px;
    li:first-child {
      font-weight: 500;
      font-size: 20px;
      margin: 25px 0;
    }
    li:not(:first-child) {
      border: 1px solid;
      border-radius: 4.1875rem;
      padding: 10px;
      margin-bottom: 15px;
      font-weight: 500;
      height: 100%;
      display: grid;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      -webkit-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
    }

    li {
      text-align: center;
    }
  }

  .btn-open {
    &.btn-d {
      color: #5F91C5;
      &:before {
        background-color: #5F91C5;
      }
      &:after {
        transform: translateY(0%) rotate(0deg);
        top: calc(50% - 10px);
      }
    }
    &.btn-c {
      color: #7D60A8;
      &:before {
        background-color: #7D60A8;
      }
      &:after {
        transform: translateY(0%) rotate(0deg);
        top: calc(50% - 10px);
      }
    }
    &.btn-b {
      color: #014A94;
      &:before {
        background-color: #014A94;
      }
      &:after {
        transform: translateY(0%) rotate(0deg);
        top: calc(50% - 10px);
      }
    }
    &.btn-a {
      color: #FE842B;
      &:before {
        background-color: #FE842B;
      }
      &:after {
        transform: translateY(0%) rotate(0deg);
        top: calc(50% - 10px);
      }
    }
  }

  &__list {
    display: none;
  }

  .btn-open + .stage-cert__list {
    display: block;
  }

  &__point +  .stage-cert__list li:not(:first-child) {
    &:before {
      content: '';
      height: 5px;
      position: absolute;
      bottom: 0;
      left: 0;
      -webkit-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
      width: 100%;
      background-color: transparent;
    }

  }


  .btn-d + .stage-cert__list li:not(:first-child) {
    color: #5F91C5;
    position: relative;
    &:before {
      background-color: #5F91C5;
    }
    &:after {
      transform: translateY(0%) rotate(0deg);
      top: calc(50% - 10px);
    }
  }
  .btn-c + .stage-cert__list li:not(:first-child) {
    color: #7D60A8;
    position: relative;
    &:before {
      background-color: #7D60A8;
    }
    &:after {
      transform: translateY(0%) rotate(0deg);
      top: calc(50% - 10px);
    }
  }
  .btn-b + .stage-cert__list li:not(:first-child) {
    color: #014A94;
    position: relative;
    &:before {
      background-color: #014A94;
    }
    &:after {
      transform: translateY(0%) rotate(0deg);
      top: calc(50% - 10px);
    }
  }
  .btn-a + .stage-cert__list li:not(:first-child) {
    color: #FE842B;
    position: relative;
    &:before {
      background-color: #FE842B;
    }
    &:after {
      transform: translateY(0%) rotate(0deg);
      top: calc(50% - 10px);
    }
  }

  &__point {
    border:1px solid;
    border-radius: 6px;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    padding: 15px;
    position:relative;
    &:before {
      content: '';
      height: 5px;
      position: absolute;
      bottom: 0;
      left: 0;
      -webkit-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
      width: 100%;
      background-color: transparent;
    }
    &:after {
      content: '';
      border: 8px solid transparent;
      border-bottom: 8px solid #000000;
      -webkit-transform: translateY(0%) rotate(180deg);
      -ms-transform: translateY(0%) rotate(180deg);
      transform: translateY(0%) rotate(180deg);
      width: 0;
      height: 0;
      display: inline-block;
      margin-left: 15px;
      margin-bottom: -3px;
      -webkit-transition: all 0.4s;
      -o-transition: all 0.4s;
      transition: all 0.4s;
      position: absolute;
      right: 25px;
      top: calc(50% - 5px);
    }
  }


  &__table-head {
    border: 1px solid #737687;
    border-radius: 6px;
    padding: 15px 20px;
    display: grid;
    text-align: center;
    align-items: center;
    grid-template-columns: 1fr 4fr 3fr;
    @include lg-block {
      grid-template-columns: auto;
    }
  }
  &__body-mobile {
    display: none;
    @include lg-block {
      display: block;
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 35px;
    }
  }
  &__body-numb {
    @include lg-block {
      display: none;
    }
  }
  &__table-body {
    margin-top: rem(25px);
    @include lg-block {
      padding: 30px 0;
      border: 1px solid #737687;
      border-radius: 6px;
    }
  }
  &__table-body-string {
    padding: 5px 21px;
    display: grid;
    text-align: center;
    align-items: center;
    grid-template-columns: 1fr 4fr 3fr;
    @include lg-block {
      grid-template-columns: 1fr 1fr;
    }
    &.hover-helper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      opacity: 0;
      @include lg-block {
        display: none;
      }
    }
  }
  &__head-stage {
    border-left: 1px solid #A7AABC;
    border-right: 1px solid #A7AABC;
    padding: 50px 0;
    @include lg-block {
      display: none;
    }
  }
  &__body-stage {
    border: 1px solid #A7AABC;
    border-radius: rem(67px);
    padding: 0  5px 0;
    height: 100%;
    display: grid;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    &:after {
      background-color: transparent;
      content: '';
      height: 4px;
      position: absolute;
      bottom: 0;
      left:0;
      width: 100%;
      transition: all 0.5s;
    }
    &.use-a.is-active-a {
      border: 1px solid #FE842B;
      color: #FE842B;
      font-weight: 700;
      &:after {
        background-color: #FE842B;
      }
    }
    &.use-b.is-active-b {
      border: 1px solid #014A94;
      color: #014A94;
      font-weight: 700;
      &:after {
        background-color: #014A94;
      }
    }
    &.use-c.is-active-c {
      border: 1px solid #9400D3;
      color: #9400D3;
      font-weight: 700;
      &:after {
        background-color: #9400D3;
      }
    }
    &.use-d.is-active-d {
      border: 1px solid #5F91C5;
      color: #5F91C5;
      font-weight: 700;
      &:after {
        background-color: #5F91C5;
      }
    }

  }

  &__head-wrap-lvl-c, &__head-wrap-lvl-b, &__head-wrap-lvl-a {
    border-left: 1px solid #A7AABC;
    @include lg-block {
      border: none;
    }
  }
  &__head-wrap-lvl-d, &__head-wrap-lvl-c, &__head-wrap-lvl-b, &__head-wrap-lvl-a {
    font-size: 24px;
    padding: 15px 0 0;
  }
  &__body-wrap-lvl-d, &__body-wrap-lvl-c, &__body-wrap-lvl-b, &__body-wrap-lvl-a {
    font-size: 24px;
    padding: 15px 0 0;
  }
  &__head-wrap-lvl, &__body-wrap-lvl {
    display: grid;
    padding-left: rem(20px);
    grid-template-columns: repeat( 4, 1fr );
    .hover-helper & {
      height: 100%;
    }
  }
  &__head-lvl {
    grid-column: 1/5;
    text-align: center;
    padding-bottom: 15px;
    @include lg-block {
      font-size: 20px;
      font-weight: 500;
    }
  }
  &__head-numb {
    padding: 50px 0;
    @include lg-block {
      display: none;
    }
  }
  .wrap-lvl {
    padding-top: 15px;
  }
  &__body-wrap-lvl > .wrap-lvl {
    padding-top: 0;
  }
  &__head-lvl-d, &__head-lvl-c, &__head-lvl-b, &__head-lvl-a,
  &__body-lvl-d, &__body-lvl-c, &__body-lvl-b, &__body-lvl-a{
    border-radius: 6px;
    font-size: 24px;
    height: rem(50px);
    width: rem(50px);
    display: grid;
    align-items: center;
    margin: auto;
    .hover-helper & {
      height: 100%;
    }
  }
  &__head-lvl-d, &__head-lvl-c, &__head-lvl-b, &__head-lvl-a {
    @include lg-block {
      height: rem(90px);
      width: rem(90px);
      font-size: 32px;
    }
  }
  &__body-wrap-lvl span {
    height: 2px;
    background: #737687;
    width: 22px;
    right: 0;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    position: absolute;
    &.cross-left {
      transform: rotate(-135deg);
      top: -2px;
      left: 13px;
      margin-top: 25px;
    }
    &.cross-right {
      transform: rotate(135deg);
      top: 16px;
      left: 13px;
      margin-top: 7px;
    }
  }

  &__head-lvl-d, &__body-lvl-d  {
    border: 1px solid #5F91C5;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    &:after {
      content: '';
      height: 5px;
      position: absolute;
      bottom: 0;
      left:0;
      transition: all 0.5s;
      width: 100%;
      background-color: transparent;
    }
    &.is-active:after {
      background-color: #5F91C5;
    }
    &.is-active span {
      background-color: #5F91C5;
    }
    &.disabled {
      opacity: 0;
    }
  }

  &__head-lvl-c, &__body-lvl-c  {
    border: 1px solid #7D60A8;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    &:after {
      content: '';
      height: 5px;
      position: absolute;
      bottom: 0;
      left:0;
      width: 100%;
      background-color: transparent;
      transition: all 0.5s;
    }
    &.is-active:after {
      background-color: #7D60A8;
    }
    &.is-active span {
      background-color: #7D60A8;
    }
    &.disabled {
      opacity: 0;
    }
  }
  &__head-lvl-b, &__body-lvl-b {
    border: 1px solid #014A94;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    &:after {
      content: '';
      height: 5px;
      position: absolute;
      bottom: 0;
      left:0;
      width: 100%;
      background-color: transparent;
      transition: all 0.5s;
    }
    &.is-active:after {
      background-color: #014A94;
    }
    &.is-active span {
      background-color: #014A94;
    }
    &.disabled {
      opacity: 0;
    }
  }
  &__head-lvl-a, &__body-lvl-a {
    border: 1px solid #FE842B;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    &:after {
      content: '';
      height: 5px;
      position: absolute;
      bottom: 0;
      left:0;
      width: 100%;
      background-color: transparent;
      transition: all 0.5s;
    }
    &.is-active:after {
      background-color: #FE842B;
    }
    &.is-active span {
      background-color: #FE842B;
    }
    &.disabled {
      opacity: 0;
    }
  }
  &__body-lvl-a, &__body-lvl-b, &__body-lvl-c, &__body-lvl-d {
    border: 1px solid #A7AABC;
  }
  &__body-lvl-d.is-active {
    border: 1px solid #5F91C5;
  }
  &__body-lvl-c.is-active {
    border: 1px solid #7D60A8;
  }
  &__body-lvl-b.is-active {
    border: 1px solid #014A94;
  }
  &__body-lvl-a.is-active {
    border: 1px solid #FE842B;
  }
  &__text {
    margin-bottom: 50px;
  }
}