.swiper-partners {
  display: block;
  position: relative;
  padding: 0  0 80px;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  @include sm-block {
    padding-left: 60px;
  }

  .swiper-container {
    @include col();
    @include size(12);
  }

  .swiper-slide {
    text-align: center;
  }

  .swiper-nav {
    position: relative;
    width: 215px;
    @include sm-block{
      display: none;
    }
  }
  .swiper-nav.mob {
    position: relative;
    width: 215px;
    display: none;
    @include sm-block{
      display: block;
      height: 54px;
      left: calc( 50% - 107px );
    }
  }
  &__top-bar {
    @include col();
    @include size(12);
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .wrapper-nav-mob {
    position: relative;
  }
  .swiper-button-prev:after {
    content: '';
    width: 15px;
    height: 15px;
    display: block;
    transform-origin: center center;
    transform: translateY(0%) rotate(135deg);
    border-bottom: solid 2px #FE842B;
    border-right: solid 2px #FE842B;
    position: absolute;
    top: calc( 50% - 9px );
    margin-top: 0;
  }
  .swiper-button-prev, .swiper-button-next {
    content: '';
    width: 50px;
    height: 50px;
    top: 50%;
    background: transparent;
  }

  .swiper-button-next:after {
    content: '';
    width: 15px;
    height: 15px;
    display: block;
    transform-origin: center center;
    transform: translateY(0%) rotate(-45deg);
    border-bottom: solid 2px #FE842B;
    border-right: solid 2px #FE842B;
    position: absolute;
    top: calc( 50% - 9px );
    margin-top: 0;
  }
  .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 0;
  }
  .swiper-button-prev, .swiper-button-next {
    z-index: 12;
  }
}