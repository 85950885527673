.news-assoc {
  display: block;
  position: relative;
  padding: rem(80px) 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  @include sm-block {
    padding-left: 50px;
  }
  &__top-bar {
    @include col();
    @include size(12);
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
  }
  &__wrapper-link {
    display: grid;
    align-items: center;
    a {
      position:relative;
      color:#000000;
      font-weight: 700;
      text-transform: uppercase;
      font-size: rem(14px);
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        background: #A7AABC;
        left: 0;
        height: 3px;
        bottom: -5px;
      }
    }
    @include sm-block{
      display: none;
    }
    &.mob {
      display: none;
      margin-top: 15px;
      @include col();
      @include size(12);
      @include sm-block {
        display: block;
      }
    }
  }

  &__subtitle {
    font-size: 16px;
    color: #737687;
    margin-bottom: 15px;
  }
  &__wrapper-main-news {
    @include col();
    @include size(6);
    @include size-lg(12);
  }
  &__wrapper-list {
    @include col();
    @include size(6);
    @include size-lg(12);
  }
  .img-main {
    max-width: 100%;
    max-height: 500px;
    transition: all 0.5s;
    &:hover {
      filter: grayscale(100%);
    }

  }
  .wrapper-main-img {
    margin-bottom: 40px;
    position: relative;
    @include lg-block{
      width: 100%;
      text-align: center;
    }
  }
  .wrapper-img {
    @include lg-block{
      width: 100%;
      text-align: center;
    }
  }
  &__text-content, &__article-content {
    margin-left: 5px;
  }
  &__text-content {
    &:hover > .news-assoc__subtitle {
      color: #5F91C5;
    }
  }
  &__main-text {
    @include lg-block{
      margin-bottom: 35px;
    }
  }
  &__title {
    margin-bottom: 15px;
  }
  &__article {
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 40px;
    display: grid;
    @include sm-block{
      grid-template-columns: 1fr;
      justify-content: center;
    }
    &:not(:last-child) {
      margin-bottom: 60px;
    }
    .wrapper-img {
      img {
        max-width: 100%;
      }
    }
  }
}