.cutaway {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include md-block {
      margin: auto;
    }
    font-size: 14px;
  }
  &__block {
    width: 100%;
    background: #E5E5E5;
    border-radius: 6px;
    padding: 55px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    @include xs-block {
      grid-template-columns: 1fr;
      padding: 55px 15px;
    }
    &:after {
      content: '';
      height: 8px;
      background: #FE842B;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  &__position {
    margin-bottom: 25px;
  }
  &__tel {
    color: #505050;
    font-size: 16px;
    &_blue {
      font-size: 14px;
      font-weight: 700;
      color: #014A94;
    }
    &_bold {
      font-size: 14px;
      font-weight: 700;
    }
  }
  &__mail {
    color: #505050;
    font-size: 16px;
    &_bold {
      font-size: 14px;
      font-weight: 700;
      color: #014A94;
      text-decoration-line: underline;
    }
  }
  &__wrap {
    display: grid;
    grid-template-columns: 140px 1fr;
    grid-column-gap: 40px;
    @include sm-block {
      grid-template-columns: auto;
      grid-row-gap: 25px;
    }
  }
  &__img {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    height: 140px;
    width: 140px;
  }
  &__position {
    color: #737687;
    font-size: 16px;
  }
  &__name {
    margin-bottom: 5px;
  }
}