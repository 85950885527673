.footer {
  display: block;
  position: relative;
  padding: rem(80px) 0 rem(30px);
  height: inherit;
  background: #002245;
  background-size: cover;
  z-index: 35;
  color: #F2F2F2;
  font-size: rem(14px);


  }


  .link-top {
    height: rem(46px);
    width: rem(46px);
    border: 1px solid #A7AABC;
    position: absolute;
    border-radius: 50%;
    right: 0;
    top: 0;
    transition: all 0.5s;
    opacity: 0;
    &:after {
      content: '';
      width: 6px;
      height: 6px;
      display: block;
      transform-origin: center center;
      transform: translateY(0%) rotate(-135deg);
      border-bottom: solid 1px #A7AABC;
      border-right: solid 1px #A7AABC;
      top: calc(50% - 3px);
      right: calc(50% - 3px);
      position: absolute;
    }

    &:hover {
      background-color: #ffffff;

      &:after {
        border-bottom: solid 1px #002245;
        border-right: solid 1px #002245;
      }
    }

    &.is-active {
      opacity: 1;
    }
    @include md-block {
      display: none;
    }
    &--mobile {
      display: block;
      position: fixed;
      bottom: 50px;
      right: 30px;
      background-color: #fff;
      z-index: 999;
      top: auto;
      border-color: rgb(226, 226, 226);
      // @include md-block {
      //   display: block;
      // }
    }
  }

  .footer-content {
    padding-bottom: rem(80px);
    position: relative;
  }

  .footer-content-logo {
    @include col();
    @include size(4);
    @include size-lg(10);
    @include shift-lg(1);
    @include size-sm(12);
    @include shift-sm(0);

    &__wrapper-logo {
      display: grid;
      grid-template-columns: rem(180px) 1fr;
      @include sm-block {
        margin-bottom: 20px;
      }
    }

    &__wrapper-logo-text {
      padding-left: rem(55px);
      justify-content: center;
      margin-bottom: 20px;
      @include lg-block {
        justify-content: normal;
        padding-left: rem(75px);
      }
    ;
      @include xs-block {
        padding-left: rem(20px);
      }
    ;
    }

    &__logo {
      img {
        width: 100%;
      }
    }

    &__wrapper-descr {
      a, ul {
        color: #8597C5;
      }

      span {
        display: block;
      }

      @include sm-block {
        display: none;
      }
    }

    &__wrapper-descr li:not(:last-child) {
      margin-bottom: rem(25px);
    }

    &__assoc {
      display: grid;
      align-items: center;
      grid-template-columns: 205px auto;

      a {
        margin-left: rem(20px);
      }
    }

    &__soc a {
      margin-left: rem(10px);
    }
  }

  .footer-content-list {
    @include col();
    @include size(4);
    @include size-lg(10);
    @include shift-lg(1);
    @include size-xs(12);
    @include shift-xs(0);
    @include sm-block {
      display: none;
    }

    &__wrapper-list {
      ul.root {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        //max-height: rem(230px);
        margin-top: rem(94px);
        @include lg-block {
          margin-top: rem(60px);
        }
        @include sm-block {
          display: block;
          max-height: none;
        }

        li.root {
          //margin-right: rem(30px);
          flex-basis: 50%;
          margin-bottom: rem(25px);
          a.root {
            color: #ffffff;
            //padding-right: rem(15px);
          }

          &.dropper {
            position: relative;
            a.root{
              position: relative;
              z-index: 0;
              &:after{
                width: 0;
                height: 0;
                border-left: 3px solid transparent;
                border-right: 3px solid transparent;
                border-top: 5px solid #A7AABD;
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -15px;
                z-index: 0;
              }
            }

          }


          &:hover {
            ul.child{
              opacity: 1;
              pointer-events: auto;
            }
          }

          ul.child {
            @include sm-block {
              display: none;
            }
            min-width: 200px;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            background: #fff;
            z-index: 22;
            border-radius: 5px;
            padding: 5px 8px;
            transition: all 0.5s;

            li.child {
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    &__wrapper-bottom {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: rem(17px);
      align-items: center;
      @include xs-block {
        display: block;
        padding-top: rem(30px);
        border-top: rem(1px) solid #003163;
      }

      div {
        height: rem(50px);
        display: flex;
        align-items: center;
      }
    }

    &__pay a {
      margin-left: rem(30px);

      img {
        width: rem(100px);
        margin-bottom: rem(8px);
      }
    }

    &__map a {
      margin-left: rem(30px);
    }
  }

  .footer-mob-menu {
    display: none;
    position: relative;
    @include sm-block {
      display: block;
      align-items: center;
    }

    &:before, &:after {
      content: '';
      background: #003163;
      height: 1px;
      width: 100vw;
      left: 0;
      position: absolute;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }

  .wrapper-burger {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .burger, .burger-text {
    display: inline-block;
  }

  .burger-text {
    text-transform: uppercase;
    font-size: 18px;
  }

  .footer-content-form {
    @include col();
    @include size(4);
    @include size-lg(10);
    @include shift-lg(1);
    @include size-sm(12);
    @include shift-sm(0);
    margin-top: rem(50px);
    @include lg-block {
      margin-top: rem(60px);
    }

    &__title {
      font-size: rem(18px);
      text-transform: uppercase;
      margin: 0 0 rem(30px);
    }

    &__input-text {
      @include lg-block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: rem(42px);
        grid-row-gap: rem(19px);
      }
    ;
      @include sm-block {
        grid-template-columns: 1fr;
        grid-row-gap: rem(10px);
      }
    }

    input[type='text'], textarea {
      display: block;
      width: rem(315px);
      padding: rem(18px) rem(17px) rem(11px);
      margin-bottom: rem(10px);
      border-radius: rem(6px);
      background: #EFEFEF;
      @include lg-block {
        width: 100%;
        height: 50px;
      }

    }

    input[ type='text']::placeholder, textarea::placeholder {
      color: #9698A5;
    }

    &__btn {
      background-color: #014A94;
      border-color: #014A94;
      padding: rem(20px) rem(60px) rem(17px);
      border-radius: rem(67px);
      font-size: rem(12px);
      text-transform: uppercase;
      color: #ffffff;
      margin-top: rem(12px);
      cursor: pointer;
      border: none;
      @include sm-block {
        width: 100%;
      }
    }
  }

  .footer-content-line {
    height: rem(1px);
    bottom: rem(70px);
    right: 0;
    position: absolute;
    background: #003163;
    width: 100%;
    @include lg-block {
      bottom: rem(50px);
    }
  }

  .success-form-holder {
    display: none;
    position: absolute;
    top: 0;
    min-height: 100%;
    border-radius: 15px;
    text-align: center;

    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    border: 2px solid #3578E5;
    color: black;
    width: 315px;
    background: #E5E5E5;

    p {
      margin-bottom: 20px;
    }
  }

  .form-field.text {
    margin-bottom: 15px;
  }

  .policy {
    max-width: 315px;

    .form-field.agree {
      width: 315px;
    }

    #FeedbackForm_agree {
      //position: absolute;
      flex-shrink: 0;
    }
  }

  form {
    position: relative;
  }

  .success .success-form-holder {
    display: flex;
  }

  .footer-signature {
    font-size: rem(12px);
    padding-top: rem(30px);

    &__left {
      @include col();
      @include size(8);
      @include size-lg(10);
      @include shift-lg(1);
      @include lg-block {
        text-align: center;
      }
    }

    &__right {
      @include col();
      @include size(4);
      @include size-lg(10);
      @include shift-lg(1);
      text-align: right;
      @include lg-block {
        text-align: center;
        margin-top: rem(5px);
      }

      a {
        color: #B4BACA;
        text-decoration: underline;
      }
    }
  }

