.certific-organiz-section {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
  }
  .text__article{
    font-size: rem(16px);
  }
  .block-seminar {
    display: grid;
    grid-template-columns: 3fr 5fr;
    grid-column-gap: 40px;
    &__img {
      min-height: 270px;
    }
  }
  .block-seminar {
    &__data {
      font-size: 14px;
      color: #828282;
      margin-bottom: 20px;
    }
    &__title {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 20px;
    }
    &__description {
      margin-bottom: 20px;
      overflow: hidden;
      max-height: 95px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      display: -webkit-box;
    }
  }
  .image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .card {
    background-position: center center;
    background-repeat: no-repeat;
    font-size: 20px;
    color: #ffffff;
    border-radius: 50px 5px 5px 5px;
    text-align: center;
    display: grid;
    align-items: center;
    @include xl-block {
      background-size: cover;
    }
    @include lg-block {
      min-height: 160px;
    }
    @include xs-block {
      display: block;
    }
  }
  .text-list {
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    @include sm-block {
      grid-template-columns: auto;
    }
    &__li {
      padding-left: 85px;
      position: relative;
      display: grid;
      min-height: 40px;
      font-size: 16px;
      background-position: left 5px;
      background-repeat: no-repeat;
      background-image: url('/static/images/image-icons/svg/i-doc.svg');
      background-size: 50px;
      background-position-y: top;
      min-height: 50px;
      &:before {
        display: none;
      }
    }
    &--clear.dots {
      display: block;
      .text-list__li {
        background: none;
        padding-left: 40px;
        margin-bottom: 15px;
        display: block;
        min-height: auto;
        position: relative;
        &::after {
          content: '';
          border-radius: 50%;
          left: 15px;
          top: 6px;
          width: 10px;
          height: 10px;
          background-color: #014A94;
          position: absolute;
        }
      }
    }
  }
  .block-card__top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    margin-bottom: 35px;
    @include lg-block {
      grid-template-columns: auto;
      grid-row-gap: 30px;
    }
    .card {
      padding: 50px 40px;
      @include sm-block {
        grid-template-columns: auto;
        grid-row-gap: 30px;
        width: 100%;
        padding: 50px 20px;
        margin: auto;
      }

    }
  }
  .block-card__bottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    @include lg-block {
      grid-template-columns: auto;
      grid-row-gap: 30px;
    }
    @include sm-block {
      display: block;
    }
    .card {
      padding: 40px 20px;
      @include sm-block {
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
  }
}