.pa-left-bar {
  @include col();
  @include size(3);
  @include size-lg(12);
  @include shift-right(1);
  @include shift-lg(0);
  @include lg-block {
    order: 2;
  }
  &__form-title {
    @include  lg-block {
      text-align: center;
    }
  }
  &__nav-list {
    margin: 0;
  }
  &__nav-list-point:not(:first-child):not(:last-child) {
    margin: rem(10px) 0;
  }
  &__nav-list-point {
    padding: rem(13px) rem(17px);
    border: 1px solid transparent;
    &:hover, &.select {
      border: 1px solid #FE842B;
      border-radius: 6px;
      .pa-left-bar__nav-list-link {
        color:#FE842B;
        font-weight: 700;
      }
    }
  }
  &__nav-list-link {
    text-transform: uppercase;
    border: 1px solid rgba(0, 0, 0, 0);
    color: #5F91C5;
    display: block;
  }
  &__wrapper-form {
    margin-top: 60px;
  }
  &__form {
    margin-top: 30px;
    @include lg-block {
      display: grid;
      grid-template-columns:  1fr 1fr;
      grid-column-gap: 40px;
      justify-content: center;
    }
    @include sm-block {
      display: block;
    }
  }
  .btn-orange {
    font-weight: 500;
    letter-spacing: 0.75px;
    font-size: 12px;
    @include lg-block {
      grid-column: 1/3;
      width: 33.3%;
      margin:  20px auto 0;
    }
    @include sm-block {
      width: 100%;
    }
  }
  &__input, &__select {
    border: 1px solid #A7AABC;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 17px 17px 10px;
    width: 100%;
    margin-bottom: 10px;
    &::placeholder {
      color: #737687;
    }
  }
  &__select {
    background: transparent;
  }
  &__container-select {
    position: relative;
    border: 1px solid #A7AABC;
    border-radius: 6px;
    margin-bottom: rem(30px);
    @include  lg-block {
      margin-bottom: rem(10px);
    }
  }
  &__container-select select {
    display: none; /*hide original SELECT element:*/
  }
  .select-selected {
    border: 1px solid #A7AABC;
    background-color: #ffffff;
    @include  lg-block {
      height: 100%;
    }
  }

  /*style the arrow inside the select element:*/
  .select-selected:after {
    position: absolute;
    content: "";
    top: 25px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid #737687;
    border-color: #737687 transparent transparent transparent;
  }

  /*point the arrow upwards when the select box is open (active):*/
  .select-selected.select-arrow-active:after {
    border-color: transparent transparent #737687 transparent;
    top: 19px;
  }

  /*style the items (options), including the selected item:*/
  .select-items div,.select-selected {
    color: #737687;
    padding: 17px 17px 10px;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    border-radius: 6px;
    user-select: none;
  }

  /*style items (options):*/
  .select-items {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #737687;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    border-radius: 6px;
  }

  /*hide the items when the select box is closed:*/
  .select-hide {
    display: none;
  }

  .select-items div:hover, .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &__wrapper-social {
    margin: 50px 0;
  }
  &__soc-link {
    color: #000000;
    margin-left: 50px;
    position: relative;
    &:after {
      bottom: -10px;
    }
  }
  .share:before {
    content: '';
    height: 30px;
    width: 30px;
    background: url( /static/images/pages/map/map/svg/share.svg ) no-repeat center center;
    position: absolute;
    left: -43px;
    top: 0;
  }
  .print:before {
    content: '';
    height: 30px;
    width: 30px;
    position: absolute;
    background: url( /static/images/pages/map/map/svg/printer.svg ) no-repeat center center;
    left: -43px;
    top:0;
  }
  &__nav {
    border-top: 1px solid #A7AABC;
    border-bottom: 1px solid #A7AABC;
    padding: rem(40px) 0;
    @include lg-block {
      display: none;
    }
  }
  &__wrap-soc-btn {
    display: grid;
    grid-template-columns: 120px 120px;
    grid-column-gap: 20px;
    margin-top: 50px;
    text-align: center;
    @include xs-block {
      grid-template-columns: 120px;
      grid-row-gap: 20px;
    }
  }
  &__wrap-link {
    @include lg-block {
      display: inline-block;
      width: 50%;
    }
    &.share {
      margin-top: 30px;
    }
    @include sm-block {
      width: auto;
    }
    &.b-lg {
      display: none;
      @include lg-block {
        display: inline;
      }
      @include sm-block {
        display: none;
      }
    }
    &.b-lg-none {
      @include lg-block {
        display: none;
      }
      @include sm-block {
        display: block;
      }
    }
  }
  &__wrap-link:last-child {
    margin-top: 60px;
  }
  &__btn-fb {
    background: #3578E5 url( /static/images/pages/map/map/svg/fb.svg ) no-repeat 22% 48%;
    border-radius: 6px;
    width: 120px;
    height: 45px;
    line-height: 45px;
    font-size: 18px;
  }
  &__btn-twitter {
    background: #1DA1F2  url( /static/images/pages/map/map/svg/twitter.svg ) no-repeat 12% 50%;
    background-size: 18px;
    border-radius: 6px;
    width: 120px;
    height: 45px;
    line-height: 45px;
    font-size: 18px;
  }

}