.publications {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include md-block {
      margin: auto;
    }
    font-size: 14px;
  }
  &__wrap-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__wrap-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 60px;
    margin-bottom: 50px;
    @include md-block {
      grid-template-columns: 1fr;
    }
  }
  &__wrap-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #A7AABC;
    margin-bottom: 25px;
  }
  &__wrap-text {
    margin-bottom: 50px;
  }
  &__title {
    color: #000;
    font-weight: 500;
    font-size: 18px;
  }
  &__description {
    font-size: 16px;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 85px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    margin: 20px 0 40px;
    -o-text-overflow: -o-ellipsis-lastline;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
  }
  &__source {
    color:#A7AABC;
    margin-bottom: 25px;
  }
  &__author {
    text-align: right;
  }
  .img {
    height: 220px;
    width: 100%;
    object-fit: cover;
  }
  &__wrap-pagination {
    position: relative;
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
    @include sm-block {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  &__page.from {
    color: #014A94;
  }
  .btn-arrow {
    border: 1px solid #A7AABC;
    height: 30px;
    width: 30px;
    display: grid;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 3px;
    transition: all 0.5s;
    &.act {
      background: #014A94;
      border: 1px solid #014A94;
    }
    &.prev {
      margin-right: 60px;
      @include sm-block {
        margin-right: 30px;
      }
      &:after {
        content: '';
        width: 6px;
        height: 6px;
        display: block;
        transform-origin: center center;
        transform: translateY(0%) rotate(135deg);
        border-bottom: solid 2px #A7AABC;
        border-right: solid 2px #A7AABC;
      }
    }
    &.next {
      margin-left: 60px;
      @include sm-block {
        margin-left: 30px;
      }
      &:after {
        content: '';
        width: 6px;
        height: 6px;
        display: block;
        transform-origin: center center;
        transform: translateY(0%) rotate(-45deg);
        border-bottom: solid 2px #A7AABC;
        border-right: solid 2px #A7AABC;
      }
    }
    &:hover {
      background: #014A94;
      border: 1px solid #014A94;
      &:after {
        border-bottom: solid 2px #FFFFFF;
        border-right: solid 2px #FFFFFF;
      }
    }
    &.act {
      background: #014A94;
      border: 1px solid #014A94;
      &:after {
        border-bottom: solid 2px #FFFFFF;
        border-right: solid 2px #FFFFFF;
      }
    }
  }
  .page {
    color: #A7AABC;
    position: relative;
    font-weight: 700;
    font-size: 14px;
    &.act {
      color: #014A94;
      &:after {
        content: '';
        background-color: #014A94;
        height: 2px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 5px;
      }
    }
  }
  &__pagination {
    position: absolute;
    top: 0;
    right: calc(50% - 150px );
    display: flex;
    @include sm-block {
      position: static;
      order: 1;
      margin-bottom: 40px ;
    }
  }
  &__card-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  &__wrap-page {
    display: flex;
    width: 120px;
    justify-content: space-between;
    margin-top: 5px;
  }
  .important-link {
    @include sm-block {
      order: 2;
    }
  }
  &__count-pages {
    font-size: 16px;
    @include sm-block {
      margin-bottom: 40px;
    }
  }
}