.review-organiz-section {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include md-block {
      margin: auto;
    }
    font-size: 14px;

  }
  .review-wrapper {
    margin-top: 50px;
    @include sm-block {
      margin: 50px 0 0 -20px;
      width: calc(100vw - 50px);
    }
  }
  .review-info {
    &__name{
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 15px;
    }
    &__kind{
      color: #4F4F4F;
      margin-bottom: 15px;
    }
    &__number, &__data {
      margin-bottom: 10px;
    }
    &__span {
      font-size: 16px;
      font-weight: 700;
    }
    &__description {
      overflow: hidden;
      display: -webkit-box;
      font-size: 16px;
      margin-bottom: 20px;
      max-height: 85px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;

    }

  }
  .review-block {
    padding: 40px 20px;
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 40px;
    &:nth-child(even) {
      background-color: #E0E0E0;
      border-radius: 6px;
      @include sm-block {
        border-radius: 0px;
      }
    }
    @include sm-block {
      padding: 35px 25px;
      grid-template-columns: auto;
      grid-row-gap: 30px;
    }
    &__img {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      min-height: 150px;
      @include sm-block {
        background-position: left center;
      }
    }

  }
  .pager-container {
    margin-top: 75px;
  }
}