.unique-magazine {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
  }
  .block-info {
    display: grid;
    grid-template-columns: 3fr 5fr;
    justify-content: center;
    grid-column-gap: 40px;
    &__description {
      .text-title {
        margin-top: 20px;
      }
    }
    img {
      @include lg-block {
        max-width: 50%;
      }
      @include sm-block {
        max-width: 100%;
      }
    }
    &__sting {
      display: block;
    }
    &__price {
      font-weight: 700;
      font-size: 18px;
    }
    @include lg-block {
      grid-template-columns: auto;
      grid-row-gap: 55px;
    }

  }
  .text-title {
    font-size: 20px;
    font-weight: 700;
  }
  .btn-blue {
    margin-bottom: 60px;
  }

  .wrapper-btn {
    margin-bottom: 50px;
  }

  .date, .author, .price {
    margin-bottom: 20px;
  }

  .text-top {
    margin-bottom: 40px;
  }

  .text-bottom {
     .text__article:last-child {
       margin-bottom: 20px;
     }
  }
  .image {
    max-width: 40%;
  }


  .btn-blue {
    text-transform: none;
    font-size: 16px;
    font-weight: 400;
    padding: 15px 0;
    width: 315px;
    @include xs-block {
      width: 100%;
    }
  }
  .price {
    &__span-text {
      font-style: italic;
      color: #828282;
      margin-right: 10px;
    }
    &__span-num {
      font-size: 24px;
      font-weight: 500;
    }
    &__span-value {
      font-size: 18px;
    }
  }
  .btn-hollow {
    border: 1px solid #014A94;
    color: #014A94;
    font-size: 16px;
    width: 200px;
    display: inline-block;
    text-transform: uppercase;
    &:hover {
      border-color: #ffffff;
      color: #ffffff;
      background-color: #014A94;
    }
    @include xs-block {
      width: 100%;
    }
  }



}