.young-crew-section {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
  }

  .goal-content {
    background-color: #014A94;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: rem(75px) 0 rem(70px);
    grid-column-gap: rem(40px);
    border-radius: 6px;
    @include sm-block {
      grid-template-columns: 1fr;
    }
  }
  .container-goal {
    margin-bottom: rem(95px);
  }
  .container-goal-text {
    color: #ffffff;
    font-size: 16px;
    padding: 30px 25px 30px 0;
    @include sm-block {
      padding: 20px;
    }

  }
  .container-goal-img {
    position: relative;
    display: flex;
    align-items: center;
  }
  .img-main {
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 380px;
    border-radius: 5px;
    margin-bottom: 60px;
    @include lg-block {
      min-height: 360px;
      background-size: contain;
    }
    @include sm-block {
      min-height: 220px;
    }
    @include xs-block {
      margin-bottom: 20px;
    }
  }
  .img-structure {
    min-height: 620px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin-bottom: 30px;
    @include lg-block {
      min-height: 540px;
    }
    @include sm-block {
      min-height: 250px;
    }
  }
  .block-warning {
    background: #F2F2F2;
    border: 3px solid #014A94;
    border-radius: 6px;
    padding: 40px;
    margin-bottom: 75px;
    &__title {
      color: #014A94;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 40px;
    }
  }
  .block-blue {
    color: #ffffff;
    padding: 20px 25px;
    background: #014A94;
    border: 3px solid #014A94;
    border-radius: 6px;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .goal-img {
    position: absolute;
    width: 100%;
    border-radius: 5px;
    @include sm-block {
      position: static;
    }
  }
  .block-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    @include lg-block {
      grid-template-columns: auto;
      grid-row-gap: 30px;
    }
  }
  .card {
    border: 3px solid #014A94;
    border-radius: 50px 5px 5px 5px;
    overflow: hidden;
    @include lg-block {
      display: grid;
      grid-template-columns: 1fr 2fr;
    }
    @include xs-block {
      display: block;
    }
    &__hat {
      min-height: 160px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
    &__text {
      padding: 20px 15px;
      display: flex;
      align-items: center;
    }
  }
  .card:nth-child(even) {
    border-color: #FE842B;
  }
  .text-list {
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    @include lg-block {
      grid-template-columns: auto;
    }
  }
  .text-list__li {
    padding-left: 85px;
    position: relative;
    display: grid;
    min-height: 40px;
    font-size: 16px;
    background-position: left 5px;
    background-repeat: no-repeat;
    background-size: 50px;
    list-style-type: none;
    &:before {
      display: none;
    }
  }

}