.map-page{
  .grid-row{
    width: 100%;
    padding-left: 20px;
    .header-text{
      font-size: 20px;
      margin-bottom: 20px;
    }
    li{


    }
    .header-list{
      margin-left: 20px;
      list-style: decimal;

      .list-point{
        margin-bottom: 20px;
        .header-sublist{
          margin-left: 20px;
        }
        li{
          margin-bottom: 20px;
          a{
            &:before{
              content: '–';
            }
          }
        }
      }
    }
  }
}
