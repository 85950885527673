.organization-section {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include md-block {
      margin: auto;
    }
    font-size: 14px;

  }
  .wrapper-input {
    display: grid;
    grid-template-columns: 3fr 2fr 3fr;
    grid-column-gap: 40px;
    margin-bottom: 25px;
    @include lg-block {
      grid-template-columns: 4fr 2fr;
      grid-row-gap: 15px;
    }
    @include sm-block {
      grid-template-columns: auto;
      width: 100%;
    }
    input {
      border: 1px solid #5F91C5;
      border-radius: 6px;
      padding: 12px 15px;
      @include lg-block {
        grid-column: 1/2;
      }
    }
  }
  .wrapper-button {
    display: grid;
    grid-template-columns: 2fr 2fr 4fr;
    grid-column-gap: 40px;
    @include lg-block {
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 75px;
    }
    @include sm-block {
      width: 100%;
      grid-template-columns: auto;
      grid-row-gap: 20px;
    }
  }
  .space {
    @include lg-block {
      display: none;
    }
  }
  .organization-wrapper {
    @include sm-block {
      margin: 50px 0 0 -30px;
      width: calc(100vw - 50px);
    }
  }
  .organization-info {
    &__name{
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 25px;
    }
    &__data{
      color: #4F4F4F;
    }
    &__number, &__data {
      margin-bottom: 10px;
    }
    &__span {
      font-size: 16px;
      font-weight: 700;
    }
    &__cont-time {
      margin-bottom: 30px;
    }
    &__description {
      overflow: hidden;
      max-height: 115px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      display: -webkit-box;
      font-size: 16px;
      margin-bottom: 20px;
    }

  }
  .organization-block {
    padding: 40px 20px;
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 40px;
    @include sm-block {
      padding: 35px 25px;
      grid-template-columns: auto;
      grid-row-gap: 50px;
    }
    &:nth-child(even) {
      background-color: #E0E0E0;
      border-radius: 6px;
    }
    &__img {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      min-height: 150px;
      @include lg-block {
        min-height: 170px;
      }
      @include sm-block {
        background-position: left center;
      }
    }
  }
  .pager-container {
    margin-top: 75px;
  }
}