.literature-section {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include md-block {
      margin: auto;
    }
    font-size: 14px;
  }
  .main-title {
    margin-bottom: 40px;
  }
  .second-title {
    margin-bottom: 40px;
  }
  .wrap-material {
    &:not(:last-child) {
      margin-bottom: 70px ;
    }
  }
  .price {
    &__span {
      &-text {
        font-style: italic;
        color: #828282;
        margin-right: 10px;
      }
      &-num {
        font-size: 24px;
        font-weight: 500;
      }
      &-value {
        font-size: 18px;
      }
    }
  }
  .material{
    &_top {
      position: relative;
      padding-bottom: 15px;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        background: #A7AABC;
        height: 1px;
        bottom: 0;
        right: 0;
      }
    }
    &_body {
      padding-top: 30px;
    }
  }
  .book {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-column-gap: 40px;
    color: #737687;
    font-size: 16px;
    @include sm-block {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    }
  }
  .permission {
    display: grid;
    justify-content: end;
    @include sm-block {
      display: block;
    }
    @include xs-block {
      margin-top: 15px;
    }
  }
  .wrap-img {
    height: 170px;
    position: relative;
  }
  .img {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-repeat: no-repeat;
    background-position: center center;
    @include sm-block {
      width: 120px;
    }
  }
  .not-img {
    background-color: #ECEFF5;
    color: #A7AABC;
    text-align: center;
    border-radius: 8px;
    height: 120px;
    padding-top: 35px;
    position: absolute;
    @include sm-block {
      width: 120px;
    }
  }
  .link {
    font-weight: 500;
    font-size: 16px;
    color: #014A94;
    text-decoration-line: underline;
  }
  .top-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    @include sm-block {
      grid-template-columns: auto;
    }
  }
  .data {
    font-size: 16px;
  }
  .name, .description, .price, .author, .top-container {
    margin-bottom: 15px;
  }
  .author {
    font-style: italic;
    color: #828282;
  }
  .price {
    color: #000;
    font-size: 18px;
  }
  .wrapper-btn {
    margin-bottom: 50px;
  }
  .btn-hollow {
    border: 1px solid #014A94;
    color: #014A94;
    font-size: 16px;
    width: 200px;
    display: inline-block;
    text-transform: uppercase;
    &:hover {
      border-color: #ffffff;
      color: #ffffff;
      background-color: #014A94;
    }
    @include xs-block {
      width:100%;
    }
  }
}