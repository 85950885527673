.contacts {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    font-size: 14px;
  }
  &__wrap-cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;

    @include sm-block {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 0;
    }
  }
  &__wrap-card {
    padding: rem(30px) rem(30px) rem(40px);
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: rem(40px);
    @include sm-block {
      grid-template-columns: auto;
      grid-row-gap: rem(40px);
    }
    @include xs-block {
      padding: rem(30px) 0 rem(40px);
    }
  }
  &__name{
    margin-bottom: 20px;
    letter-spacing: 0.25px;

  }
  .img {
    height: 285px;
    width: 285px;
    @include lg-block {
      margin: auto;
    }
    @include sm-block {
      height: 225px;
      width: 225px;
    }
  }
  .img-detail {
    @include sm-block {
      margin: auto;
      height: 225px;
      width: 225px;
    }
  }
  &__container-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__position {
    font-size: 16px;
    margin-bottom: rem(45px);
    letter-spacing: 0.15px;
  }
  &__container-links a {
    color: #5F91C5;
    font-size: 18px;
  }
  &__container-links .text {
    display: inline-block;
    margin: 0;
  }
  &__container-links .container {
    padding-left: rem(50px);
    background-repeat: no-repeat;
    background-position: left center;
    @include xs-block {
      display: grid;
    }
  }
  &__tel {
    margin-bottom: 25px;
  }
}