.individual-memb-section {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
  }
  .text__article{
    font-size: rem(16px);
  }
  .block-seminar {
    display: grid;
    grid-template-columns: 3fr 5fr;
    grid-column-gap: 40px;
    &__img {
      min-height: 270px;
    }
  }
  .block-seminar {
    &__data {
      font-size: 14px;
      color: #828282;
      margin-bottom: 20px;
    }
    &__title {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 20px;
    }
    &__description {
      margin-bottom: 20px;
      overflow: hidden;
      max-height: 95px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      display: -webkit-box;
    }
  }
  .image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .text-list {
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    @include sm-block {
      grid-template-columns: auto;
    }
    &__li {
      padding-left: 85px;
      position: relative;
      display: grid;
      min-height: 40px;
      font-size: 16px;
      background-position: left 5px;
      background-repeat: no-repeat;
      background-size: 40px;

    }
  }

  .wrapper-table {
    border: 1px solid #014A94;
    overflow: hidden;
    min-width: 770px;
    border-radius: 10px 10px 0 0;
    -webkit-border-radius: 10px 10px 0 0;
    -moz-border-radius: 10px 10px 0 0;
    -khtml-border-radius: 10px 10px 0 0;
  }

  .table {
    width: 100%;
    font-size: 18px;
    border-collapse: collapse;
    &__head {
      background-color: #014A94;
      color: #ffffff;
      th {
        padding: 20px 0;
      }
    }
    &__sting:not(:last-child) {
      border-bottom: 1px solid #014A94;
    }
    &__body {
      td {
        text-align: center;
        padding: 30px 0;
        &:not(:first-child) {
          border-left: 1px solid #014A94;
        }
      }
    }
  }
  .btn-blue {
    padding: rem(21px) 0 rem(17px);
  }
}