.books {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include md-block {
      margin: auto;
    }
    font-size: 14px;
  }
  &__main-title {
    margin-bottom: 40px;
  }
  &__second-title {
    margin-bottom: 40px;
  }
  &__wrap-material {
    &:not(:last-child) {
      margin-bottom: 70px ;
    }
  }
  &__material{
    &_top {
      position: relative;
      padding-bottom: 15px;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        background: #A7AABC;
        height: 1px;
        bottom: 0;
        right: 0;
      }
    }
    &_body {
      padding-top: 30px;
    }
  }
  &__book {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-column-gap: 40px;
    color: #737687;
    font-size: 16px;
    @include sm-block {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    }
  }
  &__wrap-img {
    height: 170px;
    position: relative;
  }
  &__img {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-repeat: no-repeat;
    background-position: center center;
    @include sm-block {
      width: 120px;
    }
  }
  &__not-img {
    background-color: #ECEFF5;
    color: #A7AABC;
    text-align: center;
    border-radius: 8px;
    height: 120px;
    padding-top: 35px;
    position: absolute;
    @include sm-block {
      width: 120px;
    }
  }
  &__link {
    font-weight: 500;
    font-size: 16px;
    color: #014A94;
    text-decoration-line: underline;
  }
  &__data {
    font-size: 16px;
    margin-bottom: 15px;
  }
  &__name, &__description, &__price, &__author, &__source {
    margin-bottom: 15px;
  }
  &__author, &__source, &__key-words {
    font-style: italic;
  }
  &__key-word {
    text-transform: uppercase;
  }
  &__price {
    color: #000;
    font-size: 18px;
  }
}