.inter-map__popup {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, .5);
  overflow-y: auto;
  display: none;
  &.is-active {
    display: block;
  }
  .bg-white {
    background-color: #ffffff;
    max-width: 1380px;
    margin: auto;
    top: 25%;
    @include lg-block {
      top:110px;
    }
    @include sm-block {
      margin:0 0 0 auto;
      width: calc(100% - 50px);
    }
    @include xs-block {
      width: 100%;
    }
  }
  .inter-map {
    @include xs-block {
      padding-left: 20px;
    }
  }
  .h3 {
    margin-bottom: 50px;
  }
  .btn-close {
    position: absolute;
    right: 15px;
    top:10px;
    width: 30px;
    height:30px;
    cursor: pointer;
    span {
      height: 3px;
      background: #555555;
      width: 34px;
      right: 0;
      transition: all 0.4s ease-in-out;
      position: absolute;
    }
  }

  .cross-left {
    transform: rotate(-135deg);
    top: -8px;
    margin-top: 25px;
  }
  .cross-right {
    transform: rotate(135deg);
    top: 10px;
    margin-top: 7px;
  }


}