.personal-account {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);

  &__certification {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 5fr 3fr;
    grid-column-gap: 40px;
    align-items: center;
    @include sm-block {
      grid-template-columns: auto;
      grid-row-gap: 20px;
    }

    &_text {
      font-size: 18px;
    }

    &_btn {
      display: grid;
      grid-template-columns: 2fr 1fr;
      @include sm-block {
        grid-template-columns: auto;
      }
    }
  }

  &__block-info {
    padding-bottom: 60px;
    border-bottom: 1px solid #A7AABC;
  }

  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include md-block {
      margin: auto;
    }
    font-size: 14px;
  }

  .title {
    margin-bottom: 45px;
  }

  th {
    border: 1px solid #A7AABC;
    padding: 18px 10px;
  }

  &__wrap-table {
    overflow: auto;
  }
  &__wrap-table-inner{
    margin-top: 45px;
    padding: 17px 20px;
    background-color: #F2F2F2;
    border-bottom: 8px solid #014A94;
    border-radius: 6px;
  }
  &__table {
    width: 100%;
    text-align: center;
    border-spacing: 0;
    border-collapse: collapse;

    &_head {
      th {
        font-weight: 700;
        border-top: none;

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    &_body{
      tr:last-child{
        td{
          border-bottom: none;
        }
      }
      td{
        border: 1px solid #A7AABC;
        border-top: none;
        padding: 18px 10px;
        &:first-child{
          border-left: none;
        }
        &:last-child{
          border-right: none;
        }
      }
    }

    &_sting {
      th {
        border-bottom: none;

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  &__extend-cert {
    width: 315px;
    margin-top: 40px;

    .btn-orange {
      display: block;
    }

    @include sm-block {
      width: auto;
    }
  }

  .private-data, .password {
    padding-top: 60px;
  }

  &__privat-form {
    width: 640px;
    display: grid;
    grid-template-columns:  1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 40px;
    margin-top: 45px;
    @include sm-block {
      width: auto;
      grid-template-columns:  auto;
    }

    &_large {
      grid-column: 1/3;
      @include sm-block {
        grid-column: 1;
      }
    }

    input {
      border-radius: 6px;
      border: 1px solid #A7AABC;
      padding: 17px 17px 11px;
      display: block;
      width: 100%;
    }

    .btn-hollow {
      margin-top: 20px;
      width: 200px;
    }
  }

  &__password {
    width: 640px;
    @include sm-block {
      width: auto;
    }

    input {
      border-radius: 6px;
      border: 1px solid #A7AABC;
      padding: 17px 17px 11px;
    }
  }

  &__wrap-login {
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
    grid-column-gap: 40px;
    margin-top: 45px;
    @include sm-block {
      grid-template-columns: auto;
      grid-row-gap: 20px;
    }
  }

  &__wrap-password {
    display: grid;
    margin-top: 25px;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 35px;
    align-items: center;
    @include sm-block {
      grid-template-columns: auto;
    }
  }

  .btn-hollow {
    border: 1px solid #FE842B;
    border-radius: 67px;
    padding: 19px 0;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-weight: 700;
    @include sm-block {
      width: 200px;
    }

    &:hover {
      color: #FE842B;
      border: 1px solid #000000;
    }
  }

  .btn-underline {
    background: transparent;
    position: relative;
    border: none;
    font-weight: 700;
    color: #000000;
    transition: all 0.5s;
    font-size: rem(14px);
    padding-bottom: 5px;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      background: #A7AABC;
      transition: all 0.5s;
      left: 0;
      bottom: 0;
      height: 3px;
    }

    &:hover {
      color: #014A94;

      &:after {
        background: #014A94;
      }
    }
  }
  .container-books {
    padding-top: 10px;
  }
  .publications__wrap-data {
    @include sm-block {
      margin-top: 10px;
    }
  }
}

.representative__wrap-card{
  .img{
    max-width: 300px;
    margin: 0 auto;
    @include sm-block {
      max-width: 250px;
    }
  }
}
.personal-account__wrap-table{
  @include sm-block {
    margin-right: -40px;
  }
}
.personal-account__wrap-table-inner{
  @include sm-block {
    overflow: auto;
    padding-right: 10px;
  }
}

.publications__wrap-card{
  .img{
    object-fit: cover;
    height: 220px;
    width: 100%;
    @include sm-block {
      height: 180px;
    }
  }
}

