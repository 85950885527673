.seminar-section {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
  }
  .block-seminar {
    display: grid;
    grid-template-columns: 3fr 5fr;
    grid-column-gap: 40px;
    @include sm-block{
      grid-row-gap: 20px;
      grid-template-columns: auto;
    }
    &__img {
      min-height: 260px;
      max-width: 100%;
      @include sm-block{
        max-height: 350px;
        object-fit: cover;
        width: 100%;
      }
    }
    &__data {
      font-size: 14px;
      color: #828282;
      margin-bottom: 20px;
    }
    &__title {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 20px;
    }
    &__description {
      margin-bottom: 20px;
      overflow: hidden;
      max-height: 95px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      display: -webkit-box;
    }
  }

  .image {
    border-radius: 8px;
  }

  .text .text__article:nth-child(2){
    margin-bottom: 20px;
  }

  .wrapper-seminars {
    .block-seminar {
      grid-template-columns: 1fr 3fr;
      @include sm-block{
        grid-template-columns: auto;
        grid-row-gap: 20px;
      }
      &:not(:last-child) {
        margin-bottom: 40px;
      }
      &__img {
        min-height: 150px;
        @include sm-block{
          width:100%;
          max-height: 350px;
          object-fit: cover;
        }
      }
    }
  }
}