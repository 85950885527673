.best-project {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  .h4 {
    margin-bottom: rem(40px);
  }
  .h5 {
    margin-bottom: rem(40px);
  }
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    font-size: 14px;

  }
  &__main-text {
    margin-bottom: rem(50px);
  }
  &__benefits {
    margin-bottom: rem(60px);
    .hat {
      font-size: rem(24px);
      color: #014A94;
      background-color: #E0E0E0 ;
      border: 1px solid #014A94;
      border-left: 9px solid #014A94;
      border-radius: 6px;
      padding: rem(22px) rem(26px) rem(20px);
      margin-bottom: rem(40px);
    }
    .body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 40px;
      grid-row-gap: 55px;

      @include md-block {
        grid-template-columns: 1fr;
      }
    }
    .title-benefit {
      letter-spacing: 0.15px;
      margin-bottom: 20px;
    }
    .benefit {
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-column-gap: 40px;
      @include sm-block{
        grid-column-gap: 20px;
      };
    }
    .container-benefits {
      margin-top: 65px;
    }
    .content-img-benefit {
      display: grid;
      justify-content: center;
    }
  }
  .wrap-steps.is-active {
    & > div {
      opacity: 1;
      transform: translate3d(0,0,0);
      transition: transform 0.2s ease-out,
      opacity 0.2s ease-out;
      @for $i from 1 to 8 {
        &:nth-child(#{$i}) { transition-delay: $i * 0.3s + 0.15s; }
      }

    }
  }

  &__steps {
    margin-bottom: rem(90px);
    .wrap-steps{
      & > div {
        opacity: 0;
        transform: translate(-100px,0);
        @include md-block{
          transform: translate(0,0);
        };
        @include sm-block{
          opacity: 1;
        };
        @include xs-block{
          margin-right: -30px;
          margin-left: -10px;
        }
      }

      display: grid;
      grid-row-gap: 10px;
      &-1{
        display: grid;
        grid-template-columns: 18fr 6fr;
        @include md-block {
          grid-template-columns: 1fr;
        }
      }
      &-2{
        display: grid;
        grid-template-columns: 1fr 18fr 5fr;
        @include md-block {
          grid-template-columns: 1fr;
        }
        &:before {
          content: '';
          background: transparent;
        }
      }
      &-3 {
        display: grid;
        grid-template-columns: 2fr 18fr 4fr;
        @include md-block {
          grid-template-columns: 1fr;
        }
        &:before {
          content: '';
          background: transparent;
        }
      }
      &-4 {
        display: grid;
        grid-template-columns: 3fr 18fr 3fr;
        @include md-block {
          grid-template-columns: 1fr;
        }
        &:before {
          content: '';
          background: transparent;
        }
      }
      &-5 {
        display: grid;
        grid-template-columns: 4fr 18fr 2fr;
        @include md-block {
          grid-template-columns: 1fr;
        }
        &:before {
          content: '';
          background: transparent;
        }
      }
      &-6 {
        display: grid;
        grid-template-columns: 5fr 18fr 1fr;
        @include md-block {
          grid-template-columns: 1fr;
        }
        &:before {
          content: '';
          background: transparent;
        }
      }
      &-7 {
        display: grid;
        grid-template-columns: 6fr 18fr;
        @include md-block {
          grid-template-columns: 1fr;
        }
        &:before {
          content: '';
          background: transparent;
        }
      }
    }
    .block-step {
      background: #ECEFF5;
      border-radius: 20px;
      height: 103px;
      padding: 8px 23px;
      display: grid;
      grid-template-columns: 10px 64px auto;
      align-items: center;
      @include xl-block {
        height: 103px;
      }
      @include md-block {
        height: 140px;
      }
      @include xs-block{
        border-radius: 0;
        height: 200px;
      }
      .word-step {
        position: relative;
        &__span {
          position: absolute;
          transform: translateY(0%) rotate(-90deg);
          width: 31px;
          letter-spacing: 0.15px;
          top: -10px;
          left: -20px;
          text-transform: uppercase;
          color: #828282;
        }
      }

      .step-numb {
        height: 100%;
        font-size: 24px;
        font-weight: 700;
        align-items: center;
        text-align: center;
        display: grid;
        border-radius: 0 50% 50% 0;
        color: #000;
        background: #FD9E28;
      }
      .step-string {
        letter-spacing: 0.1px;
        margin-left: 20px;
        color: #505050;
      }
      .title {
        font-size: 18px;
        margin-bottom: 2px;
      }
      .subtitle {
        font-size: 14px;
        margin-bottom: 5px;
      }
      .text {
        font-size: 14px;
        margin: 0;
        @include md-block {
          font-size: 12px;
        }
      }
    }

  }
  &__documentation {
    margin-bottom: rem(90px);
    .img {
      height: 48px;
      width: 48px;
      &.pdf {
        background: url( /static/images/pages/protocols/protocols/svg/pdf.svg ) no-repeat center center;
      }
      &.txt {
        background: url( /static/images/pages/protocols/protocols/svg/txt.svg ) no-repeat center center;
      }
      &.doc {
        background: url( /static/images/pages/protocols/protocols/svg/doc.svg ) no-repeat center center;
      }
      &.xls {
        background: url( /static/images/pages/protocols/protocols/svg/xls.svg ) no-repeat center center;
      }
    }
    .link {
      text-decoration-line: underline;
      color: #014A94;
      font-size: 16px;
    }
    .wrap-links {
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: 200px;
      grid-row-gap: 20px;
      margin-top: 40px;
      @include  sm-block{
        grid-template-columns: auto;
      }
    }
    .wrap-link {
      margin-left: 25px;
      display: grid;
      grid-column-gap: 40px;
      grid-template-columns: 48px auto;
      align-items: center;
      @include  xs-block{
        grid-column-gap: 20px;
      }
    }
  }
  &__graph {
    padding-left: rem(40px);
    margin-bottom: rem(90px);
    @include  sm-block{
      padding-left: 0;
      margin-bottom: rem(75px);
    }

    .container-blocks {
      display: grid;
      grid-row-gap: 20px;
    }
    .block-event {
      background: #E0E0E0;
      position: relative;
      border: 1px solid #014A94;
      border-right: 9px solid #014A94;
      border-radius: 6px;
      padding: rem(25px) rem(50px) rem(25px) rem(80px);
      @include  sm-block{
        padding: 20px;
      }
    }
    .arrow {
      position: absolute;
      height:rem(68px);
      width: rem(68px);
      background: #014A94 url( /static/images/pages/best-project/best-project/svg/arrow.svg )  no-repeat center center;
      border-radius: 50%;
      left: -35px;
      @include  sm-block{
        display: none;
      }
    }
    .title {
      font-size: 24px;
      letter-spacing: 0.25px;
      margin-bottom: 10px;
    }
    .subtitle {
      font-size: 14px;
      letter-spacing: 0.15px;
      color: #4F4F4F;
      margin-bottom: 30px;
    }
    .text {
      font-size: 16px;
      margin: 0;
    }
    .list {
      font-size: 16px;
    }

  }
  &__request {
    @include xs-block {
      margin-right: -30px;
      margin-left: -10px;
    }

    .block {
      background: #E0E0E0;
      border-radius: 6px;
      border-bottom: 10px solid #FE842B;
      padding: rem(30px) rem(40px) rem(25px);
      @include xs-block {
        border-radius: 6px;
        padding: rem(30px) rem(20px) rem(25px);
      }
    }
    .text {
      font-weight: 500;
      font-size: 24px;
      @include xs-block {
        font-size: 18px;
      }
    }
    .link {
      color: #014A94;
      text-decoration: underline;
    }
    .wrapper-btn {
      width: rem(200px);

    }
    .btn-blue {
      padding: rem(21px);
    }
  }

}