.candidates-info {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include md-block {
      margin: auto;
    }
    font-size: 14px;
  }
  &__text {
    font-size: 16px;
    margin: 25px 0;
    color: #505050;
  }
  &__title-benefits {
    margin: 75px 0 50px;
  }
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 35px;
    margin-bottom: rem(90px);
    @include lg-block {
      grid-template-columns: auto;
    }
  }

  &__benefit {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 50px;
    grid-row-gap: 35px;
    font-size: 18px;
    color: #505050;
    @include lg-block {
      grid-template-columns: 1fr 5fr;
    }
    @include sm-block {
      grid-template-columns: 1fr 4fr;
      grid-column-gap: 20px;
    }
  }

  &__wrapper-benefits {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 50px;
    grid-row-gap: 35px;
    font-size: 18px;
    color: #505050;
    margin-bottom: 90px;
    @include lg-block {
      grid-template-columns: 1fr 1fr;
    }
    @include sm-block {
      grid-template-columns: auto;
      grid-column-gap: 20px;
    }
  }
  &__point {
    align-items: center;
    font-size: 18px;
    padding-left: 90px;
    background-repeat: no-repeat;
    background-size: 60px;
    background-position: left top;
    min-height: 55px;
    @include sm-block {
      padding-left: 60px;
      font-size: 14px;
      grid-column-gap: 20px;
      background-size: 40px;
    }
    @include xs-block {
      padding-left: 42px;
      background-size: 35px;
    }
  }

  &__benefit-phrase {
    border-radius: 50px 9px 9px 9px;
    width: 100%;
    height: 190px;
    color: #ffffff;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    display: grid;
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 0 10px;
    @include lg-block {
      background-size: cover;
    }
    @include xs-block {
      font-size: 18px;
      height: 200px;
    }
    &.first {
      background-image: url( /static/images/pages/certificate/certificate/bg1.jpg );
    }
    &.second {
      background-image: url( /static/images/pages/certificate/certificate/bg2.jpg );
    }
    &.third {
      background-image: url( /static/images/pages/certificate/certificate/bg3.jpg );
    }
    &.fourth {
      background-image: url( /static/images/pages/certificate/certificate/bg4.jpg );

    }

  }
  &__wrap-steps{
    display: grid;
    grid-row-gap: 10px;
    &_one{
      display: grid;
      grid-template-columns: 6fr 3fr;
      @include sm-block {
        display: block;
      }
    }
    &_second{
      display: grid;
      grid-template-columns: 1fr 6fr 2fr;
      @include sm-block {
        display: block;
      }
      &:before {
        content: '';
        background: transparent;
      }
    }
    &_third {
      display: grid;
      grid-template-columns: 2fr 6fr 1fr;
      @include sm-block {
        display: block;
      }
      &:before {
        content: '';
        background: transparent;
      }
    }
    &_fourth {
      display: grid;
      grid-template-columns: 3fr 6fr;
      @include sm-block {
        display: block;
      }
      &:before {
        content: '';
        background: transparent;
      }
    }
    & > div {
      opacity: 0;
      transform: translate(-100px,0);
      @include sm-block{
        opacity: 1;
        transform: translate(0,0);
      };
    }
    &.is-active > div {
      opacity: 1;
      transform: translate3d(0,0,0);
      transition: transform 0.2s ease-out,
      opacity 0.2s ease-out;
      @for $i from 1 to 7 {
        &:nth-child(#{$i}) { transition-delay: $i * 0.4s + 0.15s; }
      }
    }
  }


  &__block-step {
    background: #ECEFF5;
    border-radius: 20px;
    height: 100px;
    padding: 15px 23px;
    display: grid;
    grid-template-columns: 10px 64px auto;
    align-items: center;
    @include sm-block {
      height: 130px;
    }
    @include xs-block {
      height: 220px;
      padding: 15px;
      grid-template-columns: 10px 40px auto;
    }
  }
  &__word-step {
    position: relative;
    &_span {
      position: absolute;
      transform: translateY(0%) rotate(-90deg);
      width: 31px;
      letter-spacing: 0.15px;
      top: -10px;
      left: -20px;
      text-transform: uppercase;
      color: #828282;
    }
  }

  &__step-numb {
    height: 100%;
    font-size: 24px;
    font-weight: 700;
    align-items: center;
    text-align: center;
    display: grid;
    border-radius: 0 50% 50% 0;
    color: #000;
    background: #FD9E28;
  }
  &__step-string {
    font-weight: 500;
    margin-left: 20px;
    color: #505050;
    @include xs-block {
      margin-left: 10px;
    }
  }

  .wrapper-doc {
    width: 100%;
    align-items: center;
    .btn-hollow {
      border: 1px solid #014A94;
      color: #014A94;
      width: 200px;
      font-weight: 700;
      display: inline-block;
      @include sm-block {
        width: 100%;
      }
      &:hover {
        color: #ffffff;
        border-color: #ffffff;
        background-color: #014A94;
        svg {
          fill: #ffffff;
        }
      }
    }
    .blue-link {
      display: inline-block;
      margin-left: 40px;
      @include sm-block {
        text-align: center;
      }
    }
    svg {
      height: 22px;
      width: 22px;
      display: inline-block;
      vertical-align: middle;
      fill: #014A94;
      transition: all 0.5s;
      margin-right: 6px;
    }
  }



}