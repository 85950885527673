.unique-publications {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    font-size: 14px;
  }

  .title {
    margin-bottom: 50px;
  }


  .data {
    margin-bottom: 15px;
  }

  .data, .source, .author {
    color: #828282;
  }

  .source, .author {
    font-style: italic;
  }

  .permission {
    margin-bottom: 20px;
  }

  .text-title {
    margin-bottom: 30px;
  }


  .img-main {
    background-repeat: no-repeat;
    background-position: center center;
    height: 300px;
    margin-bottom: 30px;
  }


  .source {
    font-size: 14px;
    display: flex;
    &__ul-link {
      margin-left: 5px;
    }
  }

  .block-sources {
    margin-bottom: 40px;
  }


}