.price-cert {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  padding-bottom: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include md-block {
      margin: auto;
    }
    font-size: 14px;
  }
  &__title {
    &_white {
      color: #F2F2F2;
      margin-bottom: 35px;
    }
    margin-bottom: 50px;
  }
  &__wrap-cards .wrapper-btn {
    width: 200px;
    grid-column: 1/5;
    margin: 30px auto 0;
    @include lg-block {
      grid-column: 1/3;
    }
    @include sm-block {
      grid-column: 1;
    }
    .btn-white {
      display: block;
      &:hover {
        background-color: #014A94;
      }
    }

  }

  &__wrap {
    background: url( /static/images/pages/certificate/price-cert/bg1.jpg ) no-repeat center center;
    background-size: cover;
    width: 100%;
    padding: 65px 40px;
    border-radius: 45px 0 0 0;
    margin-bottom: 85px;
    @include xs-block {
      padding: 65px 10px;
    }
  }
  &__wrap-cards {
    display: grid;
    grid-template-columns: repeat( 4, 1fr);
    grid-column-gap: 20px;
    &.cards-2{
      grid-template-columns: repeat( 2, 1fr);
    }
    &.cards-3{
      grid-template-columns: repeat( 3, 1fr);
    }
    @include lg-block {
      grid-template-columns: repeat( 2, 1fr);
      grid-row-gap: 35px;
    }
    @include sm-block {
      display: block;
    }
  }

  &__card {
    padding: 20px 5px 5px 5px;
    text-align: center;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    @include sm-block {
      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }
  &__text {
    &_grey {
      color: #737687;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
    &_white {
      color: #ffffff;
      margin-bottom: 23px;
    }
  }
  &__lvl {
    font-weight: 700;
    font-size: 32px;
    color: #000;
    margin: 15px auto 30px;
    @include sm-block {
      margin: 15px auto;
    }
  }
  &__wrap-price {
    color: #ffffff;
    background: #014A94;
    border-radius: 6px;
    padding: 16px 20px 25px;
    margin-top: auto;
  }
  &__price {
    &_bold {
      font-weight: 500;
      font-size: 24px;
    }
    &_norm {
      font-size: 18px;
    }
  }
  &__point {
    display: grid;
    align-items: center;
    height: 54px;
    padding-left: 90px;
    @include xs-block {
      height: auto;
    }
    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }
  .wrapper-doc {
    @include sm-block {
      display: grid;
      justify-items: center;
      grid-row-gap: 25px;
      a.blue-link {
        margin-left: 0;
      }
      a.btn-hollow {
        width: 100%;
      }
    }
  }

  .wrapper-btn {
    margin-top: 15px;
  }
  .btn-white {
    display: block;
    min-width: auto;
  }
  .btn-hollow {
    display: block;

  }

  .cert {
    background: url( /static/images/pages/certificate/price-cert/svg/cert.svg ) no-repeat center left;
  }
  .paper {
    background: url( /static/images/pages/certificate/price-cert/svg/paper.svg ) no-repeat center left;
  }
  .books {
    background: url( /static/images/pages/certificate/price-cert/svg/books.svg ) no-repeat center left;
  }
  &__list {
    margin-bottom: 70px;
  }
  .text {
    font-size: 16px;
    color: #505050;
  }
}
