.news {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include md-block {
      margin: auto;
    }
    font-size: 14px;
  }
  &__wrap-data {
    display: flex;

  }
  &__data {
    margin-bottom: 15px;
    display: inline-block;
  }
  &__event-title {
    margin-left: auto;
  }
  &__wrap-cards {
    display: grid;
    grid-template-columns: repeat( 2, 1fr);
    grid-column-gap: 40px;
    margin-bottom: 80px;
    @include sm-block {
      grid-template-columns: 1fr;
    }
  }
  &__wrap-card {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
  }
  &__block-sub {
    background: #E0E0E0;
    border-radius: 6px;
    padding: 50px 30px 40px;
    width: 100%;
    font-size: 18px;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    &:after {
      content: '';
      width: 100%;
      position: absolute;
      height: 4px;
      background: #5F91C5;
      bottom: 0;
      left: 0;
    }
  }
  &__wrap-img {
    text-align: center;
    margin-top: auto;
  }
  .img {
    height: 220px;
    margin-top: 45px;
    width: 100%;
    object-fit: contain;
  }
  &__sub-text {
    margin: 0;
  }
  &__wrap-sub {
    display: grid;
    grid-template-columns: 3fr 2fr 3fr;
    grid-column-gap: 30px;
    align-items: center;
    @include sm-block {
      grid-template-columns: auto;
      text-align: center;
      grid-row-gap: 30px;
    }
  }
  &__card-title {
    font-size: 18px;
    margin-bottom: rem(25px);
  }
  .btn-blue {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include sm-block {
      max-width: 300px;
      margin: auto;
    }
  }
  &__wrap-pagination {
    position: relative;
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
    @include sm-block {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  &__page.from {
    color: #014A94;
  }
  .btn-arrow {
    border: 1px solid #A7AABC;
    height: 30px;
    width: 30px;
    display: grid;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 3px;
    transition: all 0.5s;
    &.act {
      background: #014A94;
      border: 1px solid #014A94;
    }
    &.prev {
      margin-right: 60px;
      @include sm-block {
        margin-right: 30px;
      }
      &:after {
        content: '';
        width: 6px;
        height: 6px;
        display: block;
        transform-origin: center center;
        transform: translateY(0%) rotate(135deg);
        border-bottom: solid 2px #A7AABC;
        border-right: solid 2px #A7AABC;
      }
    }
    &.next {
      margin-left: 60px;
      @include sm-block {
        margin-left: 30px;
      }
      &:after {
        content: '';
        width: 6px;
        height: 6px;
        display: block;
        transform-origin: center center;
        transform: translateY(0%) rotate(-45deg);
        border-bottom: solid 2px #A7AABC;
        border-right: solid 2px #A7AABC;
      }
    }
    &.act {
      background: #014A94;
      border: 1px solid #014A94;
      &:after {
        border-bottom: solid 2px #FFFFFF;
        border-right: solid 2px #FFFFFF;
      }
    }
    &:hover {
      background: #014A94;
      border: 1px solid #014A94;
      &:after {
        border-bottom: solid 2px #FFFFFF;
        border-right: solid 2px #FFFFFF;
      }
    }
  }
  .page {
    color: #A7AABC;
    position: relative;
    font-weight: 700;
    font-size: 14px;
    &.act {
      color: #014A94;
      &:after {
        content: '';
        background-color: #014A94;
        height: 2px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 5px;
      }
    }
  }
  &__pagination {
    position: absolute;
    top: 0;
    right: calc(50% - 150px );
    display: flex;
    @include sm-block {
      position: static;
      order: 1;
      margin-bottom: 40px ;
    }
  }
  &__wrap-page {
    display: flex;
    width: 120px;
    justify-content: space-between;
    margin-top: 5px;
  }
  .important-link {
    text-transform: none;
    @include sm-block {
      order: 2;
    }
  }
  &__count-pages {
    font-size: 16px;
    @include sm-block {
      margin-bottom: 40px;
    }
  }
}
