// @import "settings";



@import "_vendors/smart-grid";
@import "_vendors/normalize";
@import "~simplebar/dist/simplebar.css";


// @import "_common/reset";
// @import "_common/grid";
 @import "_common/mixin";

// @import "_parts/typo";
// @import "_parts/article";

 @import "_parts/forms";
// @import "_parts/image_icons";

 @import "_vendors/_modal";
// @import "_vendors/_tabs";
// @import "_vendors/_slick";
// @import "_vendors/_sticky";

@import "_parts/index";
@import "_pages/main";

@import "_print/index";
