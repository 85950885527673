.protocols {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include md-block {
      margin: auto;
    }
    font-size: 14px;
  }
  &__img {
    height: 48px;
    width: 48px;
    background: url( /static/images/pages/protocols/protocols/svg/svg.svg ) no-repeat center center;
    &.pdf {
      background: url( /static/images/pages/protocols/protocols/svg/pdf.svg ) no-repeat center center;
    }
    &.txt {
      background: url( /static/images/pages/protocols/protocols/svg/txt.svg ) no-repeat center center;
    }
    &.doc {
      background: url( /static/images/pages/protocols/protocols/svg/doc.svg ) no-repeat center center;
    }
    &.xls {
      background: url( /static/images/pages/protocols/protocols/svg/xls.svg ) no-repeat center center;
    }
    &.aac {
      background: url( /static/images/pages/protocols/protocols/svg/aac.svg ) no-repeat center center;
    }

    &.ai {
      background: url( /static/images/pages/protocols/protocols/svg/ai.svg ) no-repeat center center;
    }
    &.avi {
      background: url( /static/images/pages/protocols/protocols/svg/avi.svg ) no-repeat center center;
    }
    &.bmp {
      background: url( /static/images/pages/protocols/protocols/svg/bmp.svg ) no-repeat center center;
    }
    &.cad {
      background: url( /static/images/pages/protocols/protocols/svg/cad.svg ) no-repeat center center;
    }
    &.cdr {
      background: url( /static/images/pages/protocols/protocols/svg/cdr.svg ) no-repeat center center;
    }
    &.dat {
      background: url( /static/images/pages/protocols/protocols/svg/dat.svg ) no-repeat center center;
    }
    &.dll {
      background: url( /static/images/pages/protocols/protocols/svg/dll.svg ) no-repeat center center;
    }
    &.dmg {
      background: url( /static/images/pages/protocols/protocols/svg/dmg.svg ) no-repeat center center;
    }
    &.eps {
      background: url( /static/images/pages/protocols/protocols/svg/eps.svg ) no-repeat center center;
    }
    &.fla {
      background: url( /static/images/pages/protocols/protocols/svg/fla.svg ) no-repeat center center;
    }
    &.flv {
      background: url( /static/images/pages/protocols/protocols/svg/flv.svg ) no-repeat center center;
    }
    &.gif {
      background: url( /static/images/pages/protocols/protocols/svg/gif.svg ) no-repeat center center;
    }
    &.indd {
      background: url( /static/images/pages/protocols/protocols/svg/indd.svg ) no-repeat center center;
    }
    &.iso {
      background: url( /static/images/pages/protocols/protocols/svg/iso.svg ) no-repeat center center;
    }
    &.jpg {
      background: url( /static/images/pages/protocols/protocols/svg/jpg.svg ) no-repeat center center;
    }
    &.midi {
      background: url( /static/images/pages/protocols/protocols/svg/midi.svg ) no-repeat center center;
    }
    &.mov {
      background: url( /static/images/pages/protocols/protocols/svg/mov.svg ) no-repeat center center;
    }
    &.mp3 {
      background: url( /static/images/pages/protocols/protocols/svg/mp3.svg ) no-repeat center center;
    }
    &.mpg {
      background: url( /static/images/pages/protocols/protocols/svg/mpg.svg ) no-repeat center center;
    }
    &.png {
      background: url( /static/images/pages/protocols/protocols/svg/png.svg ) no-repeat center center;
    }
    &.ppt {
      background: url( /static/images/pages/protocols/protocols/svg/ppt.svg ) no-repeat center center;
    }
    &.ps {
      background: url( /static/images/pages/protocols/protocols/svg/ps.svg ) no-repeat center center;
    }
    &.psd {
      background: url( /static/images/pages/protocols/protocols/svg/psd.svg ) no-repeat center center;
    }
    &.raw {
      background: url( /static/images/pages/protocols/protocols/svg/raw.svg ) no-repeat center center;
    }
    &.sql {
      background: url( /static/images/pages/protocols/protocols/svg/sql.svg ) no-repeat center center;
    }
    &.tif {
      background: url( /static/images/pages/protocols/protocols/svg/tif.svg ) no-repeat center center;
    }
    &.txt {
      background: url( /static/images/pages/protocols/protocols/svg/txt.svg ) no-repeat center center;
    }
    &.wmv {
      background: url( /static/images/pages/protocols/protocols/svg/wmv.svg ) no-repeat center center;
    }
    &.xml {
      background: url( /static/images/pages/protocols/protocols/svg/xml.svg ) no-repeat center center;
    }
    &.zip {
      background: url( /static/images/pages/protocols/protocols/svg/zip.svg ) no-repeat center center;
    }
  }
  &__link {
    text-decoration-line: underline;
    color: #014A94;
    font-size: 16px;
  }
  &__wrap-links {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 200px;
    grid-row-gap: 20px;
    margin-top: 40px;
    @include  sm-block{
      grid-template-columns: auto;
    }
  }
  &__wrap-link {
    margin-left: 25px;
    display: grid;
    grid-column-gap: 40px;
    grid-template-columns: 48px auto;
    align-items: center;
  }
  &__wrap-card:not(:last-child){
    margin-bottom: 40px;
  }
  &__year {
    text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
  }
  &__data {
    font-size: 16px;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
  }

}
