.about {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    font-size: 14px;
  }
  .section {
    margin-bottom: rem(50px);
  }
  .h4 {
    margin-bottom: rem(50px);
  }
  &__benefits {
    margin-top: 60px;
  }
  &__img-main {
    width: 100%;
    border-radius: 5px;
  }
  &__benefits-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 55px;
    @include md-block {
      grid-template-columns: 1fr;
    }
  }
  &__benefit {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 40px;
    align-items: center;
    @include lg-block {
      grid-template-columns: 1fr 5fr;
    }
  }
  &__container-benefits {
    margin-top: 65px;
  }
  &__content-img-benefit {
    display: grid;
    justify-content: center;
  }
  &__goal-content {
    background-color: #014A94;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: rem(95px) 0 rem(30px);
    grid-column-gap: rem(40px);
    border-radius: 6px;
    @include sm-block {
      grid-template-columns: 1fr;
    }
  }
  &__container-goal {
    margin-bottom: rem(95px);
  }
  &__container-goal-text {
    color: #ffffff;
    font-size: 16px;
    padding: 50px 25px 50px 0;
    @include sm-block {
      padding: 20px;
    }

  }
  &__container-goal-img {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__goal-img {
    position: absolute;
    width: 100%;
    border-radius: 5px;
    @include sm-block {
      position: static;
    }
  }
  &__content-directions {
    margin-bottom: rem(50px);
  }
  &__description-text {
    font-size: 16px;
    margin-bottom: rem(35px);
  }

  &__content-directions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: rem(40px);
    grid-row-gap: rem(20px);
    @include md-block {
      grid-template-columns:  1fr;
    }
    &--solo {
      grid-template-columns: 1fr;
    }
  }
  &__block-directions {
    border: 1px solid #014A94;
    margin-top: rem(45px);
    border-radius: rem(45px) 6px 6px 6px;
  }
  &__hat-directions {
    background: #014A94;
    border-radius: rem(45px) 6px 6px 6px;
    padding: rem(45px) rem(20px) rem(20px);
    position: relative;
    height: rem(130px);
    &--restyle {
      height: auto;
      padding: rem(30px) rem(20px);
      & .about__hat-directions_text {
        font-size: 20px;
        text-align: center;
        color: #fff;
      }
    }
    &_text {
      letter-spacing: 0.15px;
      color: #CFDFFF;
      font-size: 16px;
    }
    @include sm-block{
      height: auto;
    }
  }
  &__hat-container-directions {
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 8px 16px rgba(8, 35, 48, 0.2);
    height: rem(80px);
    width: rem(80px);
    display: grid;
    align-items: center;
    justify-content: center;
    top: -45px;
    position: absolute;
  }
  &__body-directions {
    padding: rem(20px) rem(20px) rem(30px);
  }


}