.centers {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include md-block {
      margin: auto;
    }

  }
  &__card {
    border: 1px solid #A7AABC;
    border-radius: 5px;
    margin-bottom: 20px;
    &_hat {
      padding: 20px 30px;
      border-bottom: 1px solid #A7AABC;
      display: grid;
      align-items: center;
      grid-template-columns: 200px 1fr;
      grid-column-gap: 20px;
      @include sm-block {
        grid-template-columns: auto;
        grid-row-gap: 20px;
      }
    }
    &_body {
      padding: 30px 20px;
      font-size: 16px;
    }
    &_logo {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      height: 80px;
      @include sm-block {
        background-position: left center;
      }
    }
  }
  &__card-details {
    display: none;
    &.is-open {
      display: block;
    }
  }
  &__card-tel {
    &_link {
      color:  #5F91C5;
    }
  }
  &__card-mail {
    &_link {
      color:  #5F91C5;
      text-decoration-line: underline;
    }
  }
  &__card-site {
    &_link {
      color:  #5F91C5;
      text-decoration-line: underline;
    }
  }
  &__card-dates {
    font-size: 16px;
    color: #505050;
    margin-bottom: 20px;
  }
  &__card-contacts {
    color: #000000;
    margin-bottom: 20px;
  }
  &__card-prog-descr {
    margin-top: 20px;
  }
  &__card-prog-text {
    margin-bottom: 30px;
    &_blue {
      font-size: 20px;
      color: #014A94;
    }
  }
  &__card-prog-name {
    &_bold.special {
      text-decoration-line: underline;
      color: #5F91C5;
    }
  }
  &__table {
    background-color: #014A94;
    border-radius: 6px;
    color: #FFFFFF;
    padding: 10px;
    text-align: center;
    margin-bottom: 30px;
    min-width: 770px;
  }
  &__table-cell {
    padding: 15px 50px 25px;
  }


  &__title-arrow {
    width: 40px;
    display: flex;
    justify-content: center;
    margin: 0 0 0 auto;
    cursor: pointer;
    &:before {
      content: '';
      border: 4px solid transparent;
      border-bottom: 4px solid #014A94;
      -webkit-transform: translateY(0%) rotate(180deg);
      -ms-transform: translateY(0%) rotate(180deg);
      transform: translateY(0%) rotate(180deg);
      display: block;
      transition: all .3s;
      width: 0;
      height: 0;
      margin: auto 0;
    }
  }
  .hiding{
    margin-bottom: 20px;
    .centers__title-arrow:before {
        -webkit-transform: translateY(0%) rotate(0deg);
        -ms-transform: translateY(0%) rotate(0deg);
        transform: translateY(0%) rotate(0deg);
    }
  }
  &__table-row {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    div:not(:first-child):before {
      content: '';
      width: 1px;
      height: 44px;
      background: #5F91C5;
      position: absolute;
      left: 0;
      top: calc( 50% - 22px );
      @include sm-block {
        height: 70%;
      }
    }
    div:nth-child(2):before {
      top: auto;
      bottom:0;
    }
    div:nth-child(4):before {
      top:0;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #5F91C5;
    }
    &.head {
      @include sm-block {
        display: none;
      }
    }
  }
  &__wrap-table {
    //overflow: auto;
    margin-bottom: 20px;
    .simplebar-track.simplebar-horizontal {
      border-radius: 7px;
      @include sm-block {
        position: sticky;
        bottom: 11px;
        background-color: #fff;
        box-shadow: 0px 0px 2px #000;
      }
    }
    .simplebar-scrollbar.simplebar-visible:before {
      opacity: 1;
    }
    .simplebar-scrollbar::before {
      background-color: #014A94;
    }
  }
  &__table-cell {
    position: relative;
    &.head-mob:before {
      display: none;
    }
  }
  &__card-title {
    margin-bottom: 30px;
    &_tiny {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
  &__title-btn {
    color: #014A94;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    border: 1px solid #014A94;
    border-radius: 6px;
    padding: 16px 22px 13px;
    @include xs-block {
      padding: 16px 5px 13px;
    }
  }
  &__title-text {
    @include xs-block {
      font-size: 14px;
    }
  }
  &__title-numb {
    padding-right: 15px;
    @include xs-block {
      padding-right: 5px;
    }
  }
  &__hide-content {
    margin-top: 25px;
    &.is-hide {
      display: none;
    }
  }
  &__list {
    padding-left: 20px;
    margin-bottom: 45px;
    .lvl-second {
      margin-top: 20px;
      font-weight: 400;
    }
  }
  &__point {
    padding-left: 20px;
    position: relative;
    margin-bottom: 13px;
    &:before {
      content: '';
      height: 6px;
      width: 6px;
      background: #FE842B;
      position: absolute;
      left: 0;
      top: 5px;
    }
  }



}