.options_list {
  margin-bottom: 25px;
  label.label {
      display: block;
      margin-bottom: 10px;
  }
  .checkbox-list li {
    display: flex;
    & input {
      width: auto;
      height: auto;
      margin-right: 15px;
    }
  }
}

