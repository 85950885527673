.left-bar-crew {
  .crew-form-text {
    font-size: 14px;
    margin-bottom: 30px;
    @include lg-block {
      text-align: center;
    }
    @include sm-block {
      text-align: left;
    }
  }

}