.lvl-info {
  .block-card {
    width:100%;
    border: 1px solid #5F91C5;
    border-radius: 50px 5px 5px 5px;
    margin-bottom: 30px;
  }
  .card-hat {
    background: #014A94;
    border-radius: 50px 5px 5px 5px;
    font-size: 24px;
    color: #ffffff;
    font-weight: 500;
    width: 100%;
    padding: 30px;
    text-align: center;
  }
  .card-body {
    padding: 40px 30px;
  }
  .text-list.dots .text-list__li:before {
    background-color: #FE842B;
  }
  .text-doc {
    font-weight: 700;
    font-size: 18px;
    display: inline-block;
    margin-right: 15px;
    @include sm-block {
      display: block;
      text-align: center;
      margin: 0 0 25px
    }
  }
  p {
    font-size: 16px;
  }

}