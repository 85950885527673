.analytics {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    font-size: 14px;
  }
  .img {
    width: 100%;
    max-height: rem(650px);
    &.circle {
      width: auto;
      @include sm-block {
        width: 100%;
      }
    }

  }
  .h4 {
    margin-bottom: 40px;
  }
  .h5 {
    margin-bottom: 50px;
  }
  &__graph {
    margin-top: 40px;
  }

  .text {
    font-size: 16px;
  }
  &__container-main-text {
    margin-bottom: rem(60px);
  }
  .lvl-a {
    margin-bottom: rem(60px);
  }
  .circle {

  }
  .legend{
    display: flex;
    margin-right: 20px;
    margin-top: 20px;
    margin-left: 30px;
    li{
      margin-right: 10px;
    }
    .point{
      width: 10px;
      height: 10px;
      display: inline-block;
      border-radius: 10px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}

