.dates-certific-section {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
  }
  .block-certificate {
    padding: 40px 50px ;
    font-size: 18px;
    @include lg-block {
      padding: 40px 0 ;
    }
    &:not(:last-child) {
      border-bottom: 3px solid #A7AABC;
    }
    &__title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    &__subtitle {
      margin-bottom: 30px;
    }
  }

  .container-info  {
    //overflow-x: auto;
    margin-bottom: 75px;

    @include lg-block {
      order: 1;
    }
    td {
      border: 1px solid #A7AABC;
      padding: 18px 10px;
      &:first-child{
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
    tr:first-child td {
      border-top: none;
    }
    tr:last-child td {
      border-bottom: none;
    }
    table {
      min-width: 770px;
      background-color: #F2F2F2;
      width: 100%;
      text-align: center;
      padding: 17px 20px;
      border-spacing: 0;
      border-bottom: 9px solid #014A94;
      border-radius: 30px 6px 6px 6px;
      @include sm-block {
        border-bottom: 0;
      }
    }

  }


  .block-info {
    font-size: 16px;
    &__name {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 20px;
    }
    &__city, &__mail, &__address, &__phone, &__data, &__site, &__person {
      padding-left: 40px;
      background-position: left top;
      background-repeat: no-repeat;
      background-size: 23px;
      margin-bottom: 20px;
      font-size: 16px;
    }
    &__person {
      &-text {
        display: block;
        font-size: 14px;
      }
      &-name {
        font-weight: 500;
        font-size: 18px;
      }
    }
    &__mail {
      background-image: url(/static/images/pages/partners/svg/email.svg);
      margin-bottom: 0;
      &_blue {
        color: #014A94;
        font-size: 18px;
        font-weight: 500;
        text-decoration: underline;
      }
    }
    &__address {
      background-image: url(/static/images/pages/partners/svg/flag.svg);
      font-weight: 500;
      font-size: 18px;
    }
    &__phone {
      background-image: url(/static/images/pages/partners/svg/phone.svg);
      &_blue {
        color: #014A94;
        font-size: 18px;
        font-weight: 500;
      }
    }
    &__site {
      background-image: url(/static/images/pages/partners/svg/planet.svg);
    }
    &__btn {
      display: inline-block;
      text-transform: capitalize;
      user-select: none;

      cursor: pointer;
    }

    &__description {
      margin-bottom: 30px;
    }
    &__wrapper-person:not(:first-child) {
      margin-top: 40px;
    }

    &__wrapper-description_hide {
      height: 0;
      opacity: 0;
      transform: scaleY(0);
      transition: opacity .5s ease-in-out;
      &.is-open {
        margin-top: 20px;
        height: auto;
        opacity: 1;
        transform: scaleY(1);
      }
    }
  }


  .pager-container {
    margin-top: 75px;
  }

}

