html {
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  @include display();
  line-height: 1.4;
  font-size: 16px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  a{
    color: #014A94;
    text-decoration: none;
  }
  li {
    list-style-type: none;
  }
  ul{
    padding: 0;
  }
  &  *:focus {
    outline: none;
  }
  &  * {
    box-sizing: border-box;
  }
  &.is-scroll-hidden {
    overflow-y: hidden;
  }
}


section {
  padding: rem(80px) 0;
  @include sm-block{
    padding: rem(40px) 0 rem(40px) rem(50px);
  }
  &:first-of-type {
    padding-top: 0;
  }
}



footer {
  flex-shrink: 0;
  background: black;
}

* {
  margin: 0;
  padding: 0;
}
html,
body {
  //height: 100%;
  @include sm-block{
    padding-top: 43px;
  }
}

form .form-field{
  input::placeholder {
    color: #000;
  }
  select {
    border: 1px solid #A7AABC;
    width: 100%;
    border-radius: 6px;
    height: 50px;
    margin-bottom: 15px;
    padding: 15px 15px 10px;
    color: #000000;
    -webkit-appearance: none;
    background: url(/static/images/parts/icon/icon-mod-triangle.svg) 96% / 15% no-repeat #ffffff;
    background-size: 10px;
  }
  option::placeholder {
    color: #A7AABC;
  }
  .policy input[type='checkbox'] {
    width: 35px;
    height: 16px;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  z-index: 1;
}

.footer {
  flex: 0 0 auto;
}

.blue-link {
  color: #2F80ED;
  text-decoration: underline;
}

.text-title {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
}

.burger > * {
  display: block;
  width: 34px;
  height: 3px;
  background: #5e5f61;
  right: 10px;
  transition: all 0.4s ease-in-out;
  position: absolute;
}
.burger-middle {
  margin-top: 15px;
}
.burger-bottom {
  margin-top: 30px;
}

.burger {
  position: relative;
  width: 54px;
  height: 54px;
  z-index: 20;
  margin-top: 22px;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  background: transparent;
  @include  sm-block{
    & > * {
      background: #ffffff;
      width: 30px;
    }
    .burger-bottom {
      margin-top: 26px;
    }
    .burger-middle {
      margin-top: 13px;
    }
  }

}



.text-list{
  margin-bottom: 30px;
  &__li {
    padding-left: 40px;
    position: relative;
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.text-list.dots .text-list__li:before {
  content: '';
  border-radius: 50%;
  left: 15px;
  top: 6px;
  width: 10px;
  height: 10px;
  background-color: #014A94;
  position: absolute;
}

.important-link {
  color: #000000;
  text-transform: uppercase;
  transition: all 0.5s;
  position:relative;
  font-weight: 700;
  font-size: rem(14px);
  padding-bottom: 5px;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    background: #A7AABC;
    transition: all 0.5s;
    left: 0;
    bottom: 0;
    height: 3px;
  }
  &.normal-font {
    text-transform: none;
    font-size: 16px;
  }
  &:hover {
    color: #014A94;
    &:after {
      background: #014A94;
    }
  }
}
.link-title {
  transition: all 0.5s;
  color: #000000;
  &:hover {
    text-decoration: underline;
    color: #014A94;
  }
}

.swiper-pagination-bullet {
  opacity: 1;
  background: #fff;
  height: 12px;
  width: 12px;

}
.swiper-pagination-bullet-active {
  background: #E10038;
}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  content: '';
  width: 15px;
  height: 15px;
  display: block;
  transform-origin: center center;
  transform: translateY(0%) rotate(135deg);
  border-bottom: solid 2px #FE842B;
  border-right: solid 2px #FE842B;
  position: absolute;
  top: 50%;
  margin-top: -4px;
}
.swiper-button-prev, .swiper-button-next {
  content: '';
  width: 50px;
  height: 50px;
  background: transparent;
}

.text {
  margin-bottom: 50px;
  font-size: 16px;
  &__article {
    margin-bottom: 30px;
  }
}

.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
  content: '';
  width: 15px;
  height: 15px;
  display: block;
  transform-origin: center center;
  transform: translateY(0%) rotate(-45deg);
  border-bottom: solid 2px #FE842B;
  border-right: solid 2px #FE842B;
  position: absolute;
  top: 50%;
  margin-top: -4px;
}
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0;
}
.swiper-pagination-current {
  color: #FE842B;
  font-size: 16px;
  margin-right: 15px;
}
.swiper-pagination-fraction {
  font-size: 20px;
}

.swiper-pagination-total {
  font-size: 16px;
  margin-left: 15px;
}

// .container-table-scroll {
//   overflow-x: auto;
// }

.f-bold {
  font-weight: 700;
}

.f-medium {
  font-weight: 500;
}

.h3 {
  margin: 0;
  font-size: 44px;
  font-weight: 700;
  line-height: 52px;
  @include lg-block{
    font-size: 36px;
  }
  @include sm-block{
    font-size: 32px;
  }
  @include xs-block{
    font-size: 28px;
  }
}
.h4 {
  margin: 0;
  font-weight: 500;
  font-size: 32px;
  line-height: 1.4;
  @include sm-block{
    font-size: 28px;
  }
  @include xs-block{
    font-size: 24px;
  }
}

.h5 {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.4;
  @include sm-block{
    font-size: 22px;
  }
  @include xs-block{
    font-size: 20px;
  }
}

.c-grey {
  color: #7A7F8E;
}

.f-ital {
  font-style: italic;
}

.pager-container {

  .pagination-block {
    position: relative;
    width: 260px;
    margin:auto;
    @include xs-block {
      width: 170px;
    }
  }
  .pagination-list {
    display: flex;
    justify-content: space-between;
    .prev a, .next a{
      color: transparent;
    }
    li:after{
      display: none;
    }
    li a{
      color: #A7AABC;
    }
    li.active a {
      color: #014A94;
      position: relative;
      &:after {
        content: '';
        background-color: #014A94;
        height: 2px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -3px;
      }
    }
    @include xs-block {
      width: 170px;
    }
  }
  .next a, .prev a{
    height: 30px;
    width: 30px;
    background: #014A94;
    display: block;
    position: absolute;
    top:-5px;
    border: 1px solid #014A94;
    border-radius: 3px;
    transition: all 0.5s;
    &:after {
      content: '';
      width: 6px;
      height: 6px;
      display: block;
      transform-origin: center center;
      border-bottom: solid 2px #ffffff;
      border-right: solid 2px #ffffff;
      position: absolute;
      right: calc(50% - 3px);
      top: calc(50% - 5px);
    }
    &:hover {
      background-color: transparent;
      border: 1px solid #A7AABC;
      &:after {
        border-bottom: solid 2px #A7AABC;
        border-right: solid 2px #A7AABC;
      }
    }
  }

  .prev a{
    left: -30px;
    &:after{
      transform: translateY(0%) rotate(135deg);
    }
  }

  .next{
    a:after{
      transform: translateY(0%) rotate(-45deg);
    }
  }
}


.title {
  margin-bottom: 75px;
  @include lg-block{
    margin-bottom: 60px;
  }
  @include sm-block{
    margin-bottom: 40px;
    line-height: 35px;
  }
}

.text {
  font-size: 16px;
  line-height: 1.4;
  color: #505050;
  font-weight: 400;
}

.data-container {
  display: flex;
  justify-content: space-between;
  color: #828282;
  margin-bottom: 15px;
  div:not(:last-child) {
    @include xs-block {
      margin-bottom: 20px;

    }
  }
  @include xs-block {
    flex-direction: column;

  }
}

.btn {
  transition: all 0.5s;
  cursor: pointer;
}
.btn-arrow-circle {
  height: 50px;
  width: 50px;
  border: 1px solid #A7AABC;
  border-radius: 50%;
  display: grid;
  justify-content: center;
  align-items: center;
  line, path {
    transition: all 0.5s;
    stroke: #000000;
  }
  &:hover {
    background-color: #5F91C5;
    line, path {
      stroke: #ffffff;
    }
  }
}
.btn-white {
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.75px;
  color: #014A94;
  min-width: 200px;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding: 20px 10px 15px;
  border-radius: 67px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  &:hover {
    color: #ffffff;
    background: transparent;
  }
}
.btn-blue {
  background: #014A94;
  border-radius: 67px;
  text-transform: uppercase;
  border: 1px solid #014A94;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  padding: 21px 10px;
  text-align: center;
  display: grid;
  align-items: center;
  transition: all 0.5s;
  letter-spacing: 0.75px;
  cursor: pointer;
  &:hover {
    border: 1px solid #014A94;
    color: #014A94;
    background: #ffffff;
  }
}

.policy {
  display: flex;
  label.required.label {
    display: inline-block;
    font-size: 12px;
  }
  #FeedbackForm_agree {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .required.label {
    color: #737687;
    font-size:14px;
    margin-left: 28px;
    cursor: pointer;
  }
  a.hover-underline {
    text-decoration: underline;
  }
}
footer .policy a{
  color: #8597C5;
}


.btn-hollow {
  transition: all 0.5s;
  cursor: pointer;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  padding: rem(21px) 0 rem(17px);
  border-radius: 67px;
  background: transparent;
  color: #000000;
  display: inline-block;
  text-align: center;
  &.yellow {
    border: 1px solid #FE842B;
    width: rem(315px);
    @include sm-block {
      width: 100%;
    }
    &:hover {
      color: #ffffff;
      background: #FE842B;
    }
  }
  &.blue {
    border: 1px solid #014A94;
    border-radius: 67px;
    &:hover {
      color: #ffffff;
      background: #014A94;
    }

  }
  &.small {
    padding: 13px 25px ;
    @include sm-block {
      padding: rem(21px) 0 rem(17px);
    }
  }
}

.btn-orange {
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  min-width: 200px;
  background-color: #FE842B;
  border: 1px solid #FE842B;
  padding: 20px 10px 15px;
  border-radius: 67px;
  transition: all 0.5s;
  cursor: pointer;
  letter-spacing: 0.75px;
  &:hover {
    background-color: transparent;
    color: #FE842B;
  }
}

.blue-block {
  background-color: #014A94;
  padding: 40px 40px 30px;
  border-radius: 50px 5px 5px 5px;
  color: #ffffff;
}

.permission .blue-link {
    padding: 9px 0 9px 40px;
    background-size: 25px;
    background-position: left center;
    background-repeat: no-repeat;
    background-image: url(/static/images/pages/literature/svg/icon-1.svg);
    @include xs-block {
      line-height: 32px;
    }
}

.img-letter {
  height: 550px;
  width: 430px;
  border: 1px solid #C4C4C4;
  border-radius: 12px;
  @include sm-block {
    height: auto;
    width: 100%;
  }
}


.wrapper {
 @include display(column);
 flex-grow: 1;
 min-width: 100%;
}

.m-b-20 {
  margin-bottom: rem(20px);
}

.m-b-30 {
  margin-bottom: rem(30px);
}

.m-b-50 {
  margin-bottom: rem(50px);
}

.color-grey {
  color: #4F4F4F;
}

.grid-wrapper {
  @include wrapper();
}

.grid-row {
  @include row-flex();
  &__alg-item{
    &_center {
      align-items: center;
    }
  }
  &__just-cont{
    &_center {
      justify-content: center;
    }
    &_space-btw{
      justify-content: space-between;
    }
  }
}

.links-holder{
  line-height: 1.4;
}
a.underline{
  border-bottom: 1px solid;
}

.top-page{
  margin-top: 60px;
  margin-bottom: 40px;
}
#spc-container{
  position: relative;
}
#spc-container.loading:before{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0, 0.3);
  content: 'Мы загружаем данные, пожалуйста подождите...';
}
//.container-info ul {
//  font-style: italic;
//  font-weight: 500;
//  font-size: 16px;
//  margin-bottom: 40px;
//  li {
//    position: relative;
//    padding-left: 25px;
//    margin-bottom: 15px;
//    color: #505050;
//    &:after {
//      content: '';
//      position: absolute;
//      background-color: #FE842B;
//      width: 8px;
//      height: 8px;
//      left: 0;
//      top: 6px;
//      border-radius: 50%;
//    }
//  }
//}
