.winners {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    font-size: 14px;
  }
  &__container-projects {
    display: none;
  }
  &__container-buttons {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-row-gap: 30px ;
    justify-content: space-between;
    margin-bottom: 55px;
    flex-wrap: wrap;
    @include md-block {
      grid-template-columns: repeat(6, auto);
    }
    //@include sm-block {
    //  grid-template-columns: repeat(4, auto);
    //}
    @include xs-block {
      grid-template-columns: repeat(3, auto);
    }
  }
  &__project-hat {
    padding: 30px 25px 20px;
    border: 1px solid #014A94;
    background: #E0E0E0;
    border-radius: 6px;
    border-left: 8px solid #014A94;
    font-size: 24px;
    color: #014A94;
  }
  &__project-text {
    letter-spacing: 0.1px;
    @include md-block {
      grid-column: 1 / 3;
    }
  }
  &__project-prize, &__project-logo {
    display: grid;
    justify-content: center;
    align-items: center;
    img {
      @include xs-block {
        width: 100%;
        padding: 20px;
      }
    }
  }
  &__company-name {
    font-size: 18px;
    margin-bottom: 15px;
  }
  &__project-name {
    margin-bottom: 10px;
  }
  &__project-description{
    height: 57px;
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
    word-wrap: break-word;
    max-width: 500px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &__project {
    display: grid;
    grid-template-columns: 1fr 2fr 5fr;
    align-items: center;
    padding: 30px 0 20px;
    border-bottom: 1px solid #C2C4CF;
    @include md-block {
      grid-template-columns: 2fr 3fr;
      grid-row-gap: 50px ;
    }
  }
  &__project:last-child {
    border-bottom: none;
  }
  .btn-hollow {
    border: 1px solid #FE842B;
    border-radius: 67px;
    padding: 20px 40px;
    display: inline-block;
    color: #000000;
    letter-spacing: 0.75px;
    font-weight: 500;
    @include md-block {
      padding: 20px 25px 17px;
    }
    @include xs-block {
      padding: 20px 20px 17px;
    }

    &:hover, &.is-active {
      color: #ffffff;
      border: 1px solid #014A94;
      background-color: #014A94;
    }
  }
  &--view {
    display: block;
  }
}