.about {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  .white-bg {
    padding-bottom: 40px;
    @include lg-block {
      padding-bottom: 370px;
    }
    @include sm-block {
      padding-bottom: 250px;
    }
  }
  .blue-bg {
    background:  #014A94;
    color: #ffffff;
    padding: 30px 0 120px;
    @include lg-block {
      padding: 330px 0 55px;
    }
    @include sm-block {
      padding: 320px 0 55px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top:0;
        left:-50px;
        width: 50px;
        height: 100%;
        background-color:  #014A94;
      }
    }
    @include xs-block {
      padding: 120px 0 55px;
    }

  }
  &__top-bar {
    @include col();
    @include size(12);
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
  }
  &__wrapper-link {
    display: inline-block;
    align-items: center;
    margin-top: 40px;
  }
  &__wrapper-img {
    @include col();
    @include size(4);
    @include size-lg(12);
    position: relative;
    @include lg-block{
      order: 2;
      width: 100%;
    };
    @include sm-block{
      width: 100%;
    };
  }
  .img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 38% center;
    object-fit: contain;
    height: 550px;
    @include lg-block{
      top: 55px;
      order: 2;
    };
    @include sm-block{
      top: 55px;
      height: 425px;
      background-size: cover;
      background-position: center center;
      z-index: 10;
    };
    @include xs-block{
      top: 55px;
      height: 315px;
      background-size: contain;
    };
  }
  &__wrapper-content {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block{
      order: 1;
    };
  }
  &__wrapper-benefits {
    @include col();
    @include shift-left(4);
    @include shift-lg-left(0);
    @include size(8);
    @include size-lg(12);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 30px;
    grid-column-gap: 40px;
    backface-visibility: hidden;
    .about__benefit {
      opacity: 0;
      transform: translate3d(0,36px,0);
      @include sm-block{
        opacity: 1;
      };
    }
    &.is-active .about__benefit {
      opacity: 1;
      transform: translate3d(0,0,0);
      transition: transform 0.2s ease-out,
      opacity 0.2s ease-out;
      @for $i from 1 to 5 {
        &:nth-child(#{$i}) { transition-delay: $i * 0.3s + 0.15s; }
      }
    }

    @include sm-block{
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    };
  }
  &__benefit-icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 80px;
    @include lg-block{
      height: 90px;
    };
  }
  &__benefit {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 40px;
    align-items: center;
    @include sm-block{
      grid-column-gap: 20px;
    };
  }
  &__title {
    font-weight: 500;
    margin-bottom: 40px;
  }
  &__benefit-text  {
    @include sm-block{
      word-break: break-all;
    };
  }
}