.learning-section {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
  }
  .blue-link {
    font-weight: 500;
  }
}



.specialist.learning {
  & h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  & .wrapper-custom {
    background-color: #ebebeb;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    & h2 {
      color: #014A94;
    }
  }

  .custom-table {
    table {
      border-collapse: collapse;
      padding: 10px;
    }
    tr:first-child {
      td {
        border-top: 0;
        font-size: 18px;
        @include xs-block {
          font-size: 13px;
          padding: 8px;
        }
      }
    }
    margin-bottom: 40px;
    & h2 {
      margin-bottom: 30px;
    }
    &--blue {
      & table {
        color:  #fff;
        background-color: #014A94;
        border-bottom: 0;
        td {
          border-color: #fff;
        }
      }

    }
    &--gray {
      & table {
        background-color: #E0E0E0;
      }
    }
  }
}