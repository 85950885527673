.inter-map {
  display: block;
  position: relative;
  padding: 5rem 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  @include sm-block {
    padding-left: 50px;
  }

  &__title {
    @include col();
    @include size(12);
    text-align: left;
    margin-bottom: 80px;
  }
  &__top-bar {
    @include col();
    @include size(12);
  }
  &__wrapper-points {
    @include col();
    @include size(2);
    @include size-lg(12);
    @include size-sm(9);
    @include shift-sm-left(3);
    @include lg-block {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 40px;
      margin-bottom: 70px;
    }
    @include sm-block {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 40px;
      grid-row-gap: 25px;
      margin-bottom: 40px;
    }
  }
  &__arrow-points {
    @include col();
    @include size(2);
    @include size-lg(12);
    @include size-sm(1);
    @include lg-block {
      height: 100px;
      width: calc(100% - 40px);
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 40px;
    }
    @include sm-block {
      grid-template-columns:  1fr;
      grid-row-gap: 25px;
      height: auto;
      width: auto;
      position: absolute;
      top: -155px;
      left: -112px;
    }
    .arrow {
      width: 35px;
      border-bottom: 1px dashed #FE842B;
      position: absolute;
      transition: all 0.5s;
      @include lg-block{
        position: relative;
        border-left: 1px dashed #FE842B;
        width: 0;
        height: 50px;
      }
      @include sm-block{
        width: 35px;
        height: 0;
        border-left: none;
      }
      &.color-blue {
        border-bottom: 1px dashed  #014A94;
        @include lg-block{
          border-left: 1px dashed #014A94;
        }
      }
      &.color-orange {
        border-bottom: 1px dashed  #FE842B;
        @include lg-block{
          border-left: 1px dashed #FE842B;
        }
      }
      &.color-purple {
        border-bottom: 1px dashed #9400D3;
        @include lg-block{
          border-left: 1px dashed #9400D3;
        }
      }
      &:after {
        content: '';
        width: 6px;
        height: 6px;
        display: block;
        transform-origin: center center;
        transform: translateY(0%) rotate(-45deg);
        position: absolute;
        top: -3px;
        right: calc(50% - 16px);
        transition: all 0.5s;
        @include lg-block {
          transform: translateY(0%) rotate(-135deg);
          right: calc(50% - 3px);
        }
        @include  sm-block {
          transform: translateY(0%) rotate(-45deg);
          top: 55px;
          right: calc(50% - 40px);
        }
        @include  xs-block {
          right: calc(50% - 30px);
        }
      }
      &.color-blue:after {
        border-bottom: 1px  solid  #014A94;
        border-right: 1px  solid #014A94;
      }
      &.color-orange:after {
        border-bottom: 1px  solid  #FE842B;
        border-right: 1px  solid #FE842B;
      }
      &.color-purple:after {
        border-bottom: 1px  solid #9400D3;
        border-right: 1px  solid #9400D3;
      }
      @include lg-block {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 40px;
        margin-bottom: 70px;
      }
    }
    .arrow-top {
      top: 80px;
      &.arrow {
        border-bottom: 1px dashed #737687;
        @include lg-block {
          border-left: 1px dashed #737687;
        }
        @include sm-block {
          border-left: none;
        }
        &.color-blue {
          border-bottom: 1px dashed #014A94;
          &:after {
            border-bottom: 1px  solid #014A94;
            border-right: 1px  solid #014A94;
          }
        }
        &:after {
          border-bottom: 1px  solid #737687;
          border-right: 1px  solid #737687;
        }
      }
      @include lg-block {
        top: 0;
        margin: 0 auto;
        height: 30px;
      }
      @include sm-block {
        height: 59px;
        top: -7px;
        left: 163px;
        width: 10vw;
      }
      @include xs-block {
        left: 122px;
        width: 14vw;
      }
    }
    .arrow-middle {
      top: 155px;
      &.arrow {
        border-bottom: 1px dashed #737687;
        @include lg-block {
          border-left: 1px dashed #737687;
        }
        @include sm-block {
          border-left: none;
        }
        &.color-orange {
          border-bottom: 1px dashed #FE842B;
          @include lg-block {
            border-left: 1px dashed #FE842B;
          }
          @include sm-block {
            border-left: none;
          }
          &:after {
            border-bottom: 1px  solid #FE842B;
            border-right: 1px  solid #FE842B;
          }
        }
        &:after {
          border-bottom: 1px  solid #737687;
          border-right: 1px  solid #737687;
        }
      }
      @include lg-block {
        top: 0;
        margin: 0 auto;
        height: 30px;
      }
      @include sm-block {
        height: 59px;
        top: -7px;
        left: 163px;
        width: 10vw;
      }
      @include xs-block {
        left: 122px;
        width: 14vw;
      }
    }
    .arrow-bottom {
      top: 232px;
      &.arrow {
        border-bottom: 1px dashed #737687;
        @include lg-block {
          border-left: 1px dashed #737687;
        }
        @include sm-block {
          border-left: none;
        }
        &.color-purple {
          border-bottom: 1px dashed #9400D3;
          &:after {
            border-bottom: 1px solid #9400D3;
            border-right: 1px  solid #9400D3;
          }
        }
        &:after {
          border-bottom: 1px  solid #737687;
          border-right: 1px  solid #737687;
        }
      }

      @include lg-block {
        top: 0;
        margin: 0 auto;
        height: 30px;
      }
      @include sm-block {
        height: 59px;
        top: -7px;
        left: 163px;
        width: 10vw;
      }
      @include xs-block {
        left: 122px;
        width: 14vw;
      }
    }
  }
  &__wrapper-descr {
    @include col();
    @include size(10);
    @include size-lg(8);
    @include size-sm(9);
    @include shift-lg(2);
    @include shift-sm(0);
    @include shift-sm-left(3);
    display: grid;
    padding: 0 10px;
    grid-template-columns: repeat( 7, 1fr);
    grid-column-gap: 10px;
    @include lg-block{
      grid-template-columns: 1fr;
      grid-row-gap: 10px;
    }

  }
  &__arrow-descr {
    @include col();
    @include size(10);
    @include size-lg(2);
    display: grid;
    grid-template-columns: repeat( 7, 1fr);
    grid-row-gap: 10px;
    @include lg-block{
      grid-template-columns: 1fr;
    }
    .arrow.is-active {
      height: 50px;
      width: 1px;
      margin: auto;
      position: relative;
      transition: all 0.5s;
      &.color-blue {
        border-right: 1px dashed #014A94;
        @include lg-block {
          border-top: 1px dashed #014A94;
        }
      }
      &.color-orange {
        border-right: 1px dashed #FE842B;
        @include lg-block {
          border-top: 1px dashed #FE842B;
        }
      }
      &.color-purple {
        border-right: 1px dashed #9400D3;
        @include lg-block {
          border-top: 1px dashed #9400D3;
        }
      }

      @include lg-block {
        border-right: none;
        height: 0;
        width: 11vw;
        right: -55px;
      }
      @include md-block {
        width: 10vw;
      }
      @include sm-block {
        right: -53px;
        top: 80px;
        width: 12vw;
      }
      @include xs-block {
        right: -15px;
        width: 15vw;
      }

      &:after {
        content: '';
        width: 4px;
        height: 4px;
        display: block;
        transform-origin: center center;
        transform: translateY(0%) rotate(45deg);
        position: absolute;
        top: 87%;
        right: calc(50% - 3px);
        transition: all 0.5s;
        @include lg-block {
          transform: translateY(0%) rotate(-45deg);
          position: absolute;
          top: -3px;
          right: 0;
        }
      }
      &.color-blue:after {
        border-bottom: 1px  solid  #014A94;
        border-right: 1px  solid #014A94;
      }
      &.color-orange:after {
        border-bottom: 1px  solid  #FE842B;
        border-right: 1px  solid #FE842B;
      }
      &.color-purple:after {
        border-bottom: 1px  solid #9400D3;
        border-right: 1px  solid #9400D3;
      }
    }
  }

  &__point {
    text-align: center;
    margin: 0 0 0 35px;
    padding: 20px 0 15px;
    border-radius: 67px;
    color: #737687;
    font-weight: 500;
    user-select: none;
    cursor: pointer;
    transition: all 0.5s;
    @include lg-block {
      margin: 0;
    }
    &.color-blue {
      border: 1px solid #014A94;
      &.is-active {
        background-color: #014A94;
        box-shadow: 0px 8px 16px rgba(8, 35, 48, 0.2);
      }
    }
    &.color-orange {
      border: 1px solid #FE842B;
      &.is-active {
        background-color: #FE842B;
        box-shadow: 0px 8px 16px rgba(8, 35, 48, 0.2);
      }
    }
    &.color-purple {
      border: 1px solid #9400D3;
      @include sm-block {
        z-index: 10;
      }
      &.is-active {
        background-color: #9400D3;
        box-shadow: 0px 8px 16px rgba(8, 35, 48, 0.2);
      }
    }
    &.is-active {
      color: #ffffff;
    }
    &:not(:last-child) {
      margin-bottom: 15px;
      @include lg-block {
        margin-bottom: 0;
      }
    }
  }
  &__wrapper-arrow .arrow:not(.is-active) {
    position: absolute;
    height: 14px;
    width: 14px;
    top: 50%;
    right: calc(50% - 7px);
    @include lg-block {
      top:-7%;
      right: -30px;
    }
    @include sm-block {
      top:75%;
      right: -30px;
    }
    @include xs-block {
      right: -15px;
    }
    &:before {
      content: '';
      height: 2px;
      background: #737687;
      width: 14px;
      right: 0;
      -webkit-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      position: absolute;
      transform: rotate(-135deg);
    }
    &:after {
      content: '';
      height: 2px;
      background: #737687;
      width: 14px;
      right: 0;
      -webkit-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      position: absolute;
      transform: rotate(135deg);
    }
  }
  &__card {
    border: 1px solid #737687;
    border-radius: 6px;
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align: center;
    transition: all 0.5s;
    @include lg-block {
      & a {
        text-align: inherit;
        display: grid;
        grid-template-columns: 120px auto;
        align-items: center;
        padding: 15px;
      }

    }
    @include sm-block {
      & a {
        padding: 15px 0 ;
      }
    }
    @include xs-block {
      & a {
        grid-template-columns: 70px auto;
      }
  
    }
    //&.is-active {
    //  border: 1px solid #FE842B;
    //}
    &.is-active.color-blue {
      border: 1px solid #014A94;
    }
    &.is-active.color-orange {
      border: 1px solid #FE842B;
    }
    &.is-active.color-purple {
      border: 1px solid #9400D3;
    }
  }
  &__line-top {
    width: 100%;
    margin: 0 100px 0 20px;
    transition: all 0.5s;
    @include lg-block {
      display: none;
    }
    &.color-blue {
      width: 79.9%;
      border-top: 1px dashed #014A94;
    }
    &.color-orange {
      border-top: 1px dashed #FE842B;
    }
    &.color-purple {
      border-top: 1px dashed #9400D3;
    }
  }
  &__line-left {
    transition: all 0.5s;
    &.color-blue {
      border-left: 1px dashed #014A94;
      @include lg-block {
        border-top: 1px dashed #014A94;
      }
    }
    &.color-orange {
      border-left: 1px dashed #FE842B;
      @include lg-block {
        border-top: 1px dashed #FE842B;
      }
    }
    &.color-purple {
      border-left: 1px dashed #9400D3;
      @include lg-block {
        border-top: 1px dashed #9400D3;
      }
    }
    &_grey {
      background-color: #ffffff;
      width:2px;
      height: 155px;
      position: absolute;
      left: -1px;
      bottom: 0;
      @include  lg-block {
        background-color: #ffffff;
        width: 89%;
        height: 2px;
        position: absolute;
        right: 0;
        left:auto;
        bottom:auto;
        top: -2px;
      }

      &:after {
        content: '';
        position: absolute;
        border-left: 1px dashed #737687;
        height: 100%;
        bottom: -1px;
        left: 0px;
        @include  lg-block {
          border-left: none;
          border-top: 1px dashed #737687;
          bottom:0;
          left: -1px;
          height: auto;
          width:100%;
        }
        @include  sm-block {
          border-top: none;
          border-left: 1px dashed #737687;
          height: 100%;
          left:0;
        }
      }
    }
    left: 0;
    position: absolute;
    height: 233px;
    margin-left: 20px;
    @include lg-block {
      left: 60px;
      top: 33px;
      position: absolute;
      width: 75.5vw;
      margin-left: 20px;
      height: 750px;

    }
    @include sm-block {
      left: 40px;
    }
    @include xs-block {
      left: 60px;
    }
    &.color-blue {
      @include lg-block {
        height: 640px;
      }
      @include sm-block {
        height: 793px;
      }

    }
    @include md-block {
      width: 73vw;

    }
    @include sm-block {
      width: 0;
      height: 895px;
      top: -102px;
    }
    @include xs-block {
      left:0;
    }
  }
  .color-orange .inter-map__line-left_grey {
    height: 75px;
    @include  lg-block {
      height: 2px;
      width: 44%;
    }
    @include  sm-block {
      width: 1px;
      height: 82px;
    }
  }
  .color-purple .inter-map__line-left_grey {
    height: 0;
    @include  lg-block {
      height: 2px;
      width: 0;
    }
    @include  sm-block {
      width: 1px;
      height: 167px;
    }
  }

  .wrapper-main {
    position: relative;
    height: 0;
  }

  .wrapper-arrow {
    position: relative;
    left: 0;
    top: -260px;
    @include lg-block {
      top: -830px;
    }
  }
  &__wrapper-arrow {
    position: relative;
    @include lg-block {
      height: 100px;
      top: 23px;
    }
    @include sm-block {
      top: 100px;
      height: 92px;
    }

  }
  .wrapper-icon {
    width: 80px;
    height: 80px;
    background: #ffffff;
    border-radius: 50%;
    position: relative;
    margin: 30px auto 30px;
    border: 1px solid #8C8C8C;
    @include lg-block{
      width: 70px;
      height: 70px;
      margin: 0;
    }
    @include sm-block{
      width: 60px;
      height: 60px;
      margin: 0 auto;
    }
    i {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
  .is-active .wrapper-icon {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    border: none;
  }
  .i-worker {
    background: url( /static/images/pages/home/inter-map/svg/worker.svg ) no-repeat no-repeat center center;
  }
  .i-contract {
    background: url( /static/images/pages/home/inter-map/svg/contract.svg ) no-repeat no-repeat center center;
  }
  .i-exam {
    background: url( /static/images/pages/home/inter-map/svg/exam.svg ) no-repeat no-repeat center center;
  }
  .i-brain {
    background: url( /static/images/pages/home/inter-map/svg/brain.svg ) no-repeat no-repeat center center;
  }
  .i-business-card {
    background: url( /static/images/pages/home/inter-map/svg/business-card.svg ) no-repeat no-repeat center center;
  }
  .i-team {
    background: url( /static/images/pages/home/inter-map/svg/team.svg ) no-repeat no-repeat center center;
  }
  .i-trophy {
    background: url( /static/images/pages/home/inter-map/svg/trophy.svg ) no-repeat no-repeat center center;
  }
  &__text  {
    position: relative;
    z-index: 15;
    padding-top: 35px;
    font-size: 16px;
    font-weight: 500;
    color: #737687;
    @include lg-block{
      padding: 0;
    }
    @include xs-block{
      font-size: 12px;
    }
  }
  .is-active &__text {
    color: #000000;
  }
  .is-active .bg-top {
      position: absolute;
      width: 100%;
      height: 50px;
      transition: all 0.5s;
      &.color-blue {
        background: #014A94;
        @include lg-block{
          background: transparent;
        }
      }
      &.color-orange {
        background: #FE842B;
        @include lg-block{
          background: transparent;
        }
      }
      &.color-purple {
        background: #9400D3;
        @include lg-block{
          background: transparent;
        }
      }
      &:before {
        position: absolute;
        bottom: -30px;
        left: -80px;
        border: 150px solid transparent;
        transform: translateY(0%) rotate(-13deg);
        content: '';
        transition: all 0.5s;
        @include lg-block{
          left: -210px;
          transform: translateY(0%) rotate(-45deg);
        }
      }
  }
  .bg-top {
    position: absolute;
    width: 100%;
    height: 50px;
    transition: all 0.5s;
    &:before {
      position: absolute;
      bottom: -30px;
      left: -80px;
      border: 150px solid transparent;
      transform: translateY(0%) rotate(-13deg);
      content: '';
      transition: all 0.5s;
      @include lg-block{
        left: -210px;
        transform: translateY(0%) rotate(-45deg);
      }
  } }
  .is-active .bg-top {
    &.color-blue:before {
      border-bottom-color: #014A94;
      border-right-color: #014A94;
    }
    &.color-orange:before {
      border-bottom-color: #FE842B;
      border-right-color: #FE842B;
    }
    &.color-purple:before {
      border-bottom-color: #9400D3;
      border-right-color: #9400D3;
    }
  }

  @media screen and (max-width: 1400px) {
    .inter-map_line-top.color-blue {
      width: 79.8%;
    }
  }

}

