.unique-seminar-section  {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    font-size: 14px;
  }
  .block-sub {
    background: #E0E0E0;
    border-radius: 6px;
    padding: 50px 30px 40px;
    width: 100%;
    font-size: 18px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    @include sm-block {
      padding: 50px 15px 40px;
    }
    &:after {
      content: '';
      width: 100%;
      position: absolute;
      height: 4px;
      background: #5F91C5;
      bottom: 0;
      left: 0;
    }
  }
  .wrap-sub {
    display: grid;
    grid-template-columns: 3fr 2fr 3fr;
    grid-column-gap: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    @include sm-block {
      grid-template-columns: auto;
      text-align: center;
      grid-row-gap: 30px;
    }
  }
  .orange-container {
    padding: 25px;
    border: 3px solid #F2994A;
    border-radius: 6px;
    font-size: 16px;
  }
  .text-list__li {
    font-weight: 500;
  }
  .container-btn {
    display: grid;
    grid-template-columns: 3fr 5fr;
    margin-bottom: 85px;
    margin-top: 50px;
    @include lg-block {
      grid-template-columns: 1fr 1fr;
    }
    @include sm-block {
      grid-template-columns: auto;
    }
  }
  .btn-blue{
    padding: 20px 10px 15px;
  }

}