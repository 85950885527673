.goals-container {
  &__content {
    display: grid;
    grid-row-gap: 10px;
    @include sm-block {
      margin: 0 -30px 0 -20px;
    }
  }
  &__block-goal {
    padding: rem(15px);
    border-radius: 20px;
    background: #E0E0E0;
    display: grid;
    grid-template-columns: 1fr 7fr;
    grid-column-gap: rem(40px);
    align-items: center;
    opacity: 0;
    transform: translate(-100px,0);
    @include sm-block{
      opacity: 1;
      align-items: center;
      grid-template-columns: 1fr 4fr;
      transform: translate(0,0);
      grid-column-gap: rem(20px);
      border-radius: 0;

    };
  }
  &__content.is-active .goals-container__block-goal {
    opacity: 1;
    transform: translate(0,0);
    transition: transform 0.2s ease-out,
    opacity 0.2s ease-out;
    @for $i from 1 to 7 {
      &:nth-child(#{$i}) { transition-delay: $i * 0.4s + 0.15s; }
    }

  }

  &__number {
    height: rem(75px);
    font-size: rem(24px);
    max-width: rem(75px);
    background-color: #FD9E28;
    border-radius:  0 50% 50% 0;
    display: grid;
    align-items: center;
    text-align: center;
  }
  &__description {
    font-size: 16px;
  }
}