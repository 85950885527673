.recertification-section {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include md-block {
      margin: auto;
    }
    font-size: 14px;

  }
  .goals-container__block-goal {
    transform: translate(0,0);
    opacity: 1;
  }
  .text-article {
    margin-bottom: 30px;
  }
  .text-list.dots .text-list__li:before {
    background-color: #FE842B ;
  }
  .stage-cert__table-head, .stage-cert__table-body-string {
    grid-template-columns:  5fr 3fr;
  }
  .stage-cert__table-head {
    @include lg-block {
      grid-template-columns:  auto;
    }
  }

  .stage-cert {
    margin:0;
  }

  .price-cert__wrap {
    padding: 25px 40px;
    @include xs-block {
      padding: 25px 10px;
    }
  }
  .text-list.list-img {
    li {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left center;
      height: 55px;
      padding-left: 100px;
      display: grid;
      align-items: center;
      @include sm-block {
        height: auto;
        background-size: 55px;
      }
      &:before {
        display: none;
      }
      &:first-child {
        background-image: url(/static/images/pages/recertification/svg/icon-1.svg);
        margin-bottom: 35px;
      }
      &:last-child {
        background-image: url(/static/images/pages/recertification/svg/icon-2.svg);
      }
    }

  }


}