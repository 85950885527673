.assessors {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    font-size: 14px;
  }
  &__wrap-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;


    @include sm-block {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 0;
    }
  }
  &__wrap-cards.is-hidden {
    display: none;
  }
  &__wrap-card {
    background: #E0E0E0;
    padding: rem(30px) rem(30px) rem(40px);
    display: grid;
    grid-template-rows: 330px 80px 60px;
    border-radius: 6px;
    @include lg-block {
      grid-template-rows: 330px 75px 60px;
    }
    @include sm-block {
      grid-template-rows: 280px 95px 80px;
    }
  }
  .img {
    height: 285px;
    width: 285px;
    @include lg-block {
      margin: auto;
    }
    @include sm-block {
      height: 225px;
      width: 225px;
    }
  }
  .img-detail {
    @include sm-block {
      margin: auto;
      height: 225px;
      width: 225px;
    }
  }
  &__position {
    font-size: 16px;
    color: #737687;
  }
}