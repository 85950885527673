@function rem($px) {
	@return $px / 16px + 0rem;
}

@mixin display($fdirect:row,$jcontent:stretch,$aitems:stretch) {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-direction: $fdirect;
	justify-content: $jcontent;
	align-items: $aitems;
}