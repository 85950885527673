.header {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  @include sm-block{
    position: fixed;
    border-bottom: 1px solid #014A94;
    z-index: 40;
    top:0;
    width: 100%;
  };
  .header-top {
    background:  #014A94;
    color: #ffffff;
    position: relative;
    padding: 13px 0;
    @include sm-block{
      background:  #ffffff;
      color: #014A94;
      z-index: 30;
    };
  }
  .header-bottom {
    padding: 40px 0;
    color: #000000;
    @include sm-block {
      display: none;
    }
  }
  .header-left-bar {
    display: none;
    opacity: 1;
    transition: all 0.4s;
    &.is-hidden {
      opacity: 0;
      left: -10%;
    }
    @include  sm-block{
      display: block;
      top: 0;
      left:0;
      padding-top: 90px;
      background-color: #014A94;
      position: fixed;
      z-index: 20;
      width: 50px;
      height: 100%;
      padding-left: 10px;
    }
  }
  .left-bar-nav {
    position: absolute;
    top: 250px;
    display: grid;
    grid-row-gap: 20px;
    &__send, &__search, &__map {
      width: 28px;
      display: block;
      height: 28px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &__wrapper-lang {
      display: grid;
      grid-row-gap: 20px;
    }
    .lang.select:after {
      right: -10px;
      top: 0;
      left: auto;
      width: 0;
      height: 19px;
    }
  }
  &__wrapper-logo {
    @include col();
    @include size(5);
    @include size-lg(4);
    @include size-sm(9);

    &.en{
      @include size(6);
    }
  }
  &__wrapper-mob-logo {
    display: none;
    @include col();
    @include size-sm(4);
    @include size-xs(6);
    @include sm-block {
      display: block;
    }
  }
  &__wrapper-slogan {
    @include col();
    @include size(5);
    @include size-lg(8);
    @include size-sm(8);
    @include size-xs(6);
    display: inline-grid;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    @include lg-block{
      font-size: 14px;
    };
    @include xs-block{
      font-size: 12px;
    };
  }
  &__mob-img-burger {
    height: 100%;
  }
  &__wrapper-links {
    @include col();
    @include size(5);
    display: grid;
    grid-template-columns: auto 1fr;
    @include size-lg(0);
    @include lg-block{
      display: none;
    };
  }
  &__wrapper-burger {
    @include col();
    @include size(1);
    @include size-sm(3);
    display: none;
    @include lg-block{
      display: grid;
      align-items: center;
      justify-content: center;
    };
    cursor: pointer;
  }
  &__wrapper-lang-map {
    @include col();
    @include size(2);
    @include size-lg(4);
    @include size-sm(0);
    font-size: 12px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    @include sm-block {
      display: none;
    }
  }
  &__wrapper-search-mobile {
    @include size(2);
    @include size-lg(2);
    display: none;
    @include lg-block{
      display: flex;
      justify-content: flex-end;
    }
    @include md-block{
      display: none;
    };
    .link-search {
      padding: 3px 15px;
      background: url(/static/images/parts/icon/icon-search.svg) no-repeat center center;
    }
  }
  &__wrapper-lang {
    display: grid;
    grid-template-columns: 22px 22px;
    grid-column-gap: 40px;
    align-items: center;
    @include lg-block{
      justify-content: center;
    };
  }
  .lang {
    color: #D1D8EA;
  }
  .select {
    color: #FFFFFF;
    font-weight: 500;
    position: relative;
    padding-bottom: 2px;
    &:after {
      content: '';
      position: absolute;
      border: 1px solid #F2F2F2;
      width: 19px;
      bottom: -22px;
      left: 0;
      @include lg-block{
        bottom: -17px;
      };
      @include sm-block{
        bottom: -7px;
      };
    }
  }
  &__wrapper-link {
    display: inline-grid;
    align-items: center;
    .question {
      color: #ffffff;
      font-weight: 500;
      font-size: 14px;
      padding-bottom: 3px;
      border-bottom: 1px dashed #D1D8EA;
    }
    &.mini-logo {
      display: inline-block;
    }
  }
  &__wrapper-messenger{
    display: grid;
    align-items: center;
    grid-template-columns:auto 100px;
  }
  &__wrapper-soc{
    display: flex;
    align-items: center;
    justify-content: center;
    a:not(:first-child,:last-child) {
      margin: 0 7px;
    }
  }
  &__wrapper-search {
    display: grid;
    align-items: center;
    @include col();
    @include size(2);
    @include lg-block{
      display: none;
    };
    @include size-lg(0);
  }

  &__wrapper-map {
    display: grid;
    justify-content: end;
    align-items: center;
    @include lg-block{
      justify-content: center;
    };
  }
  .input-search {
    border: none;
    width: 120px;
    &::placeholder {
      font-size: 16px;
    }
  }
  .header-mob-bottom {
    background-color: #ffffff;
    padding-bottom: 35px;
    .wrapper-input {
      display: flex;
      justify-content: center;
    }
  }
  &__wrapper-contacts {
    display: grid;
    text-align: right;
    align-items: center;
    @include col();
    @include size(3);
    @include size-lg(3);
    @include size-md(4);
    @include size-sm(0);
    &.en{
      @include size(6);
    }
    @include sm-block{
      display: none;
    }
  }
  &__wrapper-login {
    @include col();
    @include size(2);
    @include size-sm(0);
    @include sm-block{
      display: none;
    };
    display: grid;
    align-items: center;
  }
  .btn-login {
    padding: 17px 7px 14px;
    color: #000000;
    font-weight: 500;
    border-radius: 67px;
    width: 100%;
    display: block;
    text-align: center;
    font-size: 14px;
    &.btn-xl {
      border: 1px solid #FE842B;
      @include lg-block{
        display: none;
      };
    }
    &.btn-lg {
      border: 1px solid #FE842B;
      display: none;
      @include lg-block{
        display: block;

      };
    }
  }
  .tel {
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    @include lg-block {
      font-size: 18px;
    }
  }
  .email {
    margin-top: -30px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #014A94;
  }
  .img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: url( /static/images/pages/home/about/mans.jpg ) no-repeat 38% center;
    object-fit: contain;
    height: 550px;
    @include lg-block{
      top: 55px;
      order: 2;
    };
    @include sm-block{
      top: 55px;
      height: 425px;
      background-size: cover;
    };
  }
  .btn-search {
    background: #ffffff;
    border: none;
    padding: 0;
  }
  .i-enter {
    margin-right: 5px;
  }
  .header-nav {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    padding-bottom: 35px;
    @include lg-block{
      display: none;
    };
  }
  .header-main-section {
    @include col();
    @include size(2);
    display: grid;
    align-items: center;
    text-align: center;
    @include xl-block {
      margin-right: 0;
    }
    border: 1px solid #014A94;
    &.header-section {
      height:50px;

      &:before {
        right: 15px;
        top: calc(50% - 1px);
      }
    }
    border-radius: 6px;

  }
  .header-sections {
    @include col();
    @include size(12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    & > div {
      max-width: 120px;
    }

  }
  .header-section {
    text-align: center;
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100%;
    &.last-section > .header-list {
      right: 0;
    }
    &:hover > .header-list{
      opacity: 1;
      visibility: visible;
    }
    //&:before {
    //  content: '';
    //  position: absolute;
    //  border: 4px solid transparent;
    //  border-bottom: 4px solid #000000;
    //  transform: translateY(0%) rotate(180deg);
    //  display: block;
    //  width: 0;
    //  height: 0;
    //  top: calc(50% - 2px);
    //  right: 0px;
    //}
    &:after {
      content: '';
      position: absolute;
      left: 0;
      height: 30px;
      width: 100%;
      bottom: -25px;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
    &.active, &:hover{
      &:before{
        opacity: 1;
        visibility: visible;
      }
      .header-text{
        color: #014A94;
        &:after{
          border-bottom-color: #014A94;
        }
      }
    }
    &:before{
      position: absolute;
      left: -11px;
      top: -9px;
      right: -13px;
      bottom: -10px;
      border-radius: 6px;
      border: 1px solid #014A94;
      content: '';
      transition: all 0.4s;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
  }
  .header-text {
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    @include xl-block {
      font-size: 14px;
    }
    &:after {
      content: '';
      border: 4px solid transparent;
      border-bottom: 4px solid #000000;
      transform: translateY(0%) rotate(180deg);
      display: block;
      width: 0;
      height: 0;
      margin-top: 5px;
      margin-left: 5px;
      transition: opacity 0.4s;
      //top: calc(50% - 2px);
      //right: 0px;
    }
  }
  .header-li-text {
    user-select: none;
    cursor: pointer;
    position: relative;

    &.point-accordion {
      padding-right: 15px;
      &:after {
        content: '';
        border: 6px solid transparent;
        border-bottom: 6px solid #014A94;
        transform: translateY(0%) rotate(180deg);
        transition: all 0.2s ease-in-out;
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        right: 0;
        top: 10px;
      }
    }
  }
  .list-subpoint {
    transition: all 0.5s;
    position: relative;
    cursor: pointer;
    padding: 0 20px;
    line-height: 30px;
    font-size: 14px;
    &:hover {
      color: #FE842B;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  .header-list {
    position: absolute;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    z-index: 10;
    top:100%;
    padding: 30px 0 ;
    min-width: 330px;
    text-align: left;
    background: #ffffff;
    color: #5F91C5;
    text-transform: uppercase;
    text-shadow: none;
    font-weight: 400;
    margin-top: 10px;
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.2s;
    transition-delay: 0.15s;
    &:hover {
      opacity: 1;
      visibility: visible;
    }
  }

  .list-point {
    position: relative;
    cursor: pointer;
    padding: 0 20px;
    line-height: 30px;
    a {
      color: #014A94;
      &:hover {
        color: #FE842B;
        .header-li-text.point-accordion:after {
          border-bottom: 6px solid #FE842B;
        }
      }
    }

    &:hover .header-sublist{
      opacity: 1;
      visibility: visible;

    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  .header-sublist {
    border-radius: 6px;
    background-color: #ffffff;
    position: absolute;
    padding: 30px 0;
    right: calc(-100% - 15px);
    min-width: 340px;
    box-shadow: 0 5px 20px rgba(0,0,0,0.1);
    top: -5px;
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.2s;
    transition-delay: 0.15s;
  }

  .last-section .header-sublist {
    left: calc(-100% - 15px);
    right:auto;
  }

  .header-mob-top {
    background-color: #ffffff;
    display: grid;
    grid-template-columns: 4fr 8fr;
    padding: 13px 9px;
    height:rem(65px);
    align-items: center;
    @include sm-block {
      height:rem(90px);
    }
    .header__wrapper-slogan-burger {
      margin: 0 10px;
      font-size: 14px;
      color: #014A94;
      font-weight: 500;
      @include xs-block {
        font-size: 14px;
      }
    }
  }
  .header-mob-middle {
    padding: 20px;
    //height: 215px;
    text-align: right;
    background-color: #014A94;
    .btn-login {
      color: #ffffff;
      @include lg-block {
        display: flex;
        justify-content: center;
        margin: rem(30px) auto 0;
        width: rem(225px);
        .icon {
          margin-right: 10px;
        }
      }
    }
    .link-mob-email {
      letter-spacing: 0.15px;
      font-size: 18px;
      color: #FFFFFF;
      width: rem(150px);
      display: block;
      margin: 18px auto auto;
    }
    .link-mob-phone {
      color: #ffffff;
      margin: 23px auto 0;
      font-weight: 500;
      font-size: 22px;
      display: block;
      width: 200px;
      border-bottom: 1px dashed #FFFFFF;
    }
  }
  .header-mob-cross {
    position: relative;
    height: 35px;
    width: 35px;
    display: inline-block;
    cursor: pointer;
    & > span {
      height: 3px;
      background: #ffffff;
      width: 34px;
      right: 0;
      transition: all 0.4s ease-in-out;
      position: absolute;
    }
  }
  .header-mob {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    top: 0;
    z-index: 30;
    background: #0f192a;
    transition: .25s ease-out;
    display: none;
    left: 160%;
    //&:before {
    //  content: '';
    //  position: absolute;
    //  left: -30%;
    //  top: 0;
    //  width: 30%;
    //  height: 100%;
    //  opacity: 0;
    //  background: rgba(0,0,0,.5);
    //  transition: .25s ease-out .2s;
    //}
    &__list.lvl1 ,&__list.lvl2 {
      overflow: hidden;
      line-height: 0;
      transition: all .5s ease-in-out;
      transform-origin: left top;
      font-weight: 600;
      height: 0;
      color: #908385;
      transform: scaleY(0);
    }

    &__list.lvl1 > .header-mob__list-point {
      border-bottom: 1px solid #A0A0A0;
    }

    @include lg-block {
      display: block;

      &.menu-open {
        left: 0;
        //&:before {
        //  opacity: 1;
        //}
      }
    }
    &__wrap-links {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 100px 100px;
      margin-top: 30px;
    }
    &__wrapper-soc {
      margin-top: 30px;
      display: grid;
      grid-template-columns: repeat(5, 30px);
      grid-column-gap: 25px;
      justify-content: end;
      @include lg-block {
        .soc-vk:before{
          background: url(/static/images/parts/icon/icon-vk-blue.svg) no-repeat center;
        }
        .soc-vk {
          background: url(/static/images/parts/icon/icon-vk-blue.svg) no-repeat center;
        }
      }

    }
    &__wrapper-search {
      margin-bottom: 25px;
    }
    &__wrapper-contacts {
      margin-bottom: 30px;
    }
    &__wrapper-login {
      color: #000000;
    }
    &__point {
      justify-content: center;
      text-align: center;
      &.btn-open .header-mob__list.lvl1 {
        line-height: 22px;
        height: auto;
        transform: scaleY(1);
      }
    }
    &__list-point.btn-open >.header-mob__li-text  {
      & > .header-mob__link  {
        color: #FE842B;
      }

      & > .header-mob__list.lvl2  {
        line-height: 22px;
        height: auto;
        transform: scaleY(1);
      }
    }

    .point-accordion.lvl0 > .header-mob__border-link  , .point-accordion.lvl1 > .header-mob__li-text {
      .header-mob__arrow {
        width: 25px;
        display: inline-block;
        margin-left: 5px;
        cursor: pointer;
        &:after {
          content: '';
          border: 5px solid transparent;
          border-bottom: 5px solid #014A94;
          transform: translateY(0%) rotate(180deg);
          width: 0;
          height: 0;
          display: inline-block;
          margin-bottom: -3px;
          transition: all 0.4s;
        }
      }
    }
     .point-accordion.btn-open.lvl1 > .header-mob__li-text {
      .header-mob__arrow:after {
          border-bottom-color: #FE842B;
      }
    }

    .point-accordion.btn-open.lvl0 > .header-mob__border-link  , .point-accordion.btn-open.lvl1 > .header-mob__li-text {
      .header-mob__arrow:after {
          margin-bottom: 2px;
          transform: translateY(0%) rotate(0deg);
      }
    }

    &__link {
      color: #000000;
      font-weight: 500;
      font-size: 16px;
      display: inline-block;
      position: relative;
      padding: 15px 0;
      &.lvl1 {
        text-transform: uppercase;
        font-weight: 400;
      }
      &.lvl2 {
        font-weight: 400;
      }

    }
    .header-mob__border-link {
      border: 1px solid transparent;
      border-radius: 4px;
    }

    .btn-open {
      .header-mob__border-link {
        border-color: #014A94;

      }
      &>.header-mob__link {
        color: #014A94;
        border-color: #014A94;
      }
    }
    .btn-login {
      border: 1px solid #FE842B;
      background-color: #FE842B;

    }
    .input-search {
      border-bottom: 1px dashed #737687;
      &::placeholder {
        text-shadow: none;
      }
    }
  }

  .header-mob-main {
    background-color: #ffffff;
    width: 100%;
    padding: 40px 20px;
    text-align: right;
  }
  .cross-left {
    transform: rotate(-135deg);
    top: -8px;
    margin-top: 25px;
  }
  .cross-right {
    transform: rotate(135deg);
    top: 10px;
    margin-top: 7px;
  }
}
