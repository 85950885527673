@mixin image-icon($path, $width, $height)
{
  &.#{$path} {
    background-size: contain;
    width: $width;
    height: $height;
    @include image-ico($path, $width, $height);
  }
}

@mixin with-icon($path, $width, $height)
{
  &.#{$path} {
    background-size: $width $height;
    @include image-ico($path, $width, $height);
  }
}

.image-icon{
  display: inline-block;
  vertical-align: middle;
  //@include image-icon('example', 20px, 34px);
}

/*
{icon:raw 'unchecked'}
<i class="svg-icon icon-unchecked"></i>
*/

.svg-icon {
  display: inline-block;
  vertical-align: middle;
}

.soc-vk:before {
  content: '';
  padding: 0 15px;
  background: url(/static/images/parts/icon/icon-vk.svg ) no-repeat center;
}

.soc-fb:before {
  content: '';
  padding: 0 15px;
  background: url(/static/images/parts/icon/icon-fb.svg ) no-repeat center;
}

.soc-inst:before {
  content: '';
  padding: 0 15px;
  background: url(/static/images/parts/icon/icon-inst.svg ) no-repeat center;
}

.soc-telegr:before {
  content: '';
  padding: 0 15px;
  background: url(/static/images/parts/icon/icon-telegr.svg ) no-repeat center;
}

.soc-twitter:before {
  content: '';
  padding: 0 15px;
  background: url(/static/images/parts/icon/icon-twitter.svg ) no-repeat center;
}
.soc-youtube:before {
  content: '';
  padding: 0 15px;
  background: url(/static/images/parts/icon/icon-youtube.svg ) no-repeat center;
}

.i-search {
  content: '';
  padding: 3px 15px;
  background: url(/static/images/parts/icon/icon-search.svg ) no-repeat center center;
}

.i-enter {
  content: '';
  padding: 3px 12px;
  &.orange {
    background: url(/static/images/parts/icon/icon-login-orange.svg ) no-repeat center center;
  }
  &.green {
    background: url(/static/images/parts/icon/icon-login-green.svg ) no-repeat center center;
  }

}
