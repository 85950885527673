.search-page {
  display: block;
  position: relative;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  .title {
    @include col();
    @include size(12);
  }
  .search-area {
    @include col();
    @include size(12);
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-column-gap: 40px;
    @include sm-block {
      grid-template-columns: auto;
      grid-row-gap: 40px;
    }
  }
  .input-wrapper {
    position: relative;
    height: 55px;
    &__btn {
      position: absolute;
      right: 15px;
      top: 10px;
      cursor: pointer;
      user-select: none;
      border: none;
      width: 25px;
      height: 25px;
      background: none;
      span {
        height: 3px;
        background: #7D7F91;
        width: 25px;
        right: 0;
        position: absolute;
      }
    }
    &__input {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      border: 1px solid #A7AABD;
      padding: 20px 45px 20px 20px;
      font-size: 16px;
    }
  }
  .search-area {
    margin-bottom: 25px;
  }
  .cross-left {
    transform: rotate(-135deg);
    top: -8px;
    margin-top: 25px;
  }
  .cross-right {
    transform: rotate(135deg);
    top: 10px;
    margin-top: 7px;
  }
  .btn-blue {
    color:  #FFFFFF;
    &:hover {
      color: #014A94;
    }
  }
  .results-sum {
    @include col();
    @include size(12);
    font-size: 20px;
  }
  .results-wrapper {
    @include col();
    @include size(12);
    margin-top: 25px;
    margin-bottom: 85px;
  }
  .results-block {
    border-top: 1px solid #E0E0E0;
    padding-top: 30px;
    margin-bottom: 40px;
  }
  .result-link {
    letter-spacing: 0.25px;
    font-weight: 500;
    font-size: 18px;

  }
  .result-description {
    word-wrap: break-word;
    overflow: hidden;
    max-height: 85px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    margin: 20px 0 40px;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
  }
  .result-change {
    font-size: 16px;
    margin-bottom: 25px;
    &__date-span {

    }
  }
  .result-path {
    &__li {
      display: inline-block;

    }
    &__list-ul {
      display: inline-block;
      margin-left: 40px;
      & li:not(:first-child) {
        padding-left: 20px;
        position: relative;
        &:before {
          content: '';
          background-color: #A7AABC;
          position: absolute;
          top: 9px;
          left: 8px;
          width: 3px;
          height: 3px;
        }
      }
      & li:not(:last-child) {
        color: #A7AABC;
      }
    }
  }
  .pager-container {
    @include col();
    @include size(3);
  }

}