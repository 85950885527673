.map {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    font-size: 14px;
  }
  .maps-views {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    margin-bottom: rem(60px);
    @include lg-block {
      grid-template-columns: 1fr 1fr;
    }
    @include sm-block {
      grid-template-columns: 1fr 1fr;

    }
  }
  .main-map {
    grid-row: 1/3;
    @include lg-block {
      grid-row: 1;
      grid-column: 1/3;
      height: 500px;
    }
    @include sm-block {
      height:300px;
    }
  }
  .second-map, .third-map {
    height: 250px;
  }
  .img {
    max-width: 100%;
  }

  &__title-underline {
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    line-height: 16px;
    letter-spacing: 0.75px;
    position: relative;
    margin-bottom: rem(40px);
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: -7px;
      left: 0;
      background: #FE842B;
      height: 2px;
    }
  }
  &__title-content {
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.25px;
    margin-bottom: rem(40px);
  }
  &__list-point {
    padding-left: rem(50px);
    margin-bottom: rem(60px);
    &:not(:last-child) {
      margin-bottom: rem(25px);
    }
  }
  &__list-text {
    letter-spacing: 0.1px;
  }
  &__list-link {
    font-size: 18px;
    font-weight: 500;
    color: #014A94;
  }
  &__list-info {
    font-weight: 500;
    font-size: 18px
  }
  .tel {
    background: url( /static/images/pages/map/map/svg/phone.svg ) no-repeat left center;
  }
  .address {
    background: url( /static/images/pages/map/map/svg/flag.svg ) no-repeat left center;
  }
  .email {
    background: url( /static/images/pages/map/map/svg/email.svg ) no-repeat left center;
  }
  .site {
    background: url( /static/images/pages/partners/svg/planet.svg ) no-repeat left center;
  }
  .mail {
    background: url( /static/images/pages/map/map/svg/mail.svg ) no-repeat left center;
  }
  &__requisites {
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    padding-right: 20px;
    letter-spacing: 0.75px;
    position: relative;
    &.active{
      &:after{
        transform: translateY(-3px) rotate(0deg);
      }
    }
    &:after {
      transition: all 0.5s;
      content: '';
      border: 4px solid transparent;
      border-bottom: 6px solid #000000;
      transform: translateY(0%) rotate(180deg);
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      right: 0;
      bottom: calc(50% - 6px);
    }
  }
  #contacts-req{
    margin-top: 20px;
    opacity: 0;
    visibility: hidden;
    transition: opacity, visibility, transform 0.3s;
    height: 0px;
    transform: translateY(30px);
    &.active{
      transform: translateY(0px);
      opacity: 1;
      visibility: visible;
      height: auto;
    }
  }
}
