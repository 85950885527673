form{

  &.with-success{
    position: relative;
    &.success{
      .success-form-holder{
        opacity: 1;
        visibility: visible;
        pointer-events: none;
      }
    }
    .success-form-holder{
      transition: all 0.9s;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255,255,255, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      opacity: 0;
      visibility: hidden;
      pointer-events: auto;
      width: 100%;
    }
  }
}





@keyframes preloader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.preloader-form {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.151);
  }
  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 15px);
    left:  calc(50% - 15px);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-bottom-color: transparent;
    z-index: 1;
    animation: preloader-rotate 2s infinite linear;
  }
}