.test-drive {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include md-block {
      margin: auto;
    }
    font-size: 14px;
  }
  &__text {
    font-size: 16px;
    color: #505050;
  }
  .important-link {
    color: #014A94;
    text-transform: capitalize;
    &:after {
      background-color: #014A94;
      bottom:0;
    }
  }
  &__list {
    margin-top: 50px;
  }
  &__list-point {
    &:not(:first-child) {
      margin-top: 30px;
    }
  }
  &__btn {
    display: inline-block;
    margin-top: 40px;
    a {
      padding-left: 40px;
      padding-right: 40px;
      width: auto;
    }
  }
}