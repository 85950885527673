.left-bar-delta  {
  @include col();
  @include size(3);
  @include size-lg(12);
  @include shift-right(1);
  @include shift-lg(0);
  @include lg-block {
    order: 2;
  }
  &__form-title {
    @include  lg-block {
      text-align: center;
    }
  }
  &__nav-list {
    margin: 0;
  }
  &__nav-list-point:not(:first-child):not(:last-child) {
    margin: rem(10px) 0;
  }
  &__nav-list-point {
    padding: rem(13px) rem(17px);
    border: 1px solid transparent;
    &:hover, &.select {
      border: 1px solid #FE842B;
      border-radius: 6px;
      .left-bar-delta__nav-list-link {
        color:#FE842B;
        font-weight: 700;
      }
    }
  }
  &__nav-list-link {
    text-transform: uppercase;
    border: 1px solid rgba(0, 0, 0, 0);
    color: #5F91C5;
    display: block;
  }
  &__btn {
    margin-top: 30px;
  }
}