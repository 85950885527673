.events {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  &__top-bar {
    @include col();
    @include size(12);
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
  }
  &__wrapper-link {
    display: grid;
    align-items: center;
    @include sm-block{
      display: none;
    }
    &.mob {
      display: none;
      margin-top: 15px;
      @include col();
      @include size(12);
      @include sm-block {
        display: block;
      }
    }
  }

  &__subtitle {
    font-size: 16px;
    color: #737687;
    transition: all 0.5s;
    margin-bottom: 25px;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__wrapper-card {
    @include col();
    @include size(4);
    @include size-lg(12);
    position: relative;
    &:hover > .events__subtitle {
      color: #5F91C5;
    }
    padding-top: 47px;
  }
  &__title-card {
    font-size: 24px;
  }
  &__title-content {
    margin-top: 27px;
    color: #505050;
  }
}