.breadcrumbs {
  display: block;
  position: relative;
  padding: rem(20px)  0 rem(40px);
  height: inherit;
  background-size: cover;
  color: #5C5C5C;
  font-size: rem(14px);
  border-top: 1px solid #9BA1B1;
  @include sm-block {
      padding-left: 60px;
      border-top: none;
  }
  &__list {
    @include col();
    @include size(12);
    display: inline-flex;
  }
  &__item-link {
    color: #A7AABC;
  }
  &__item.delimiter {
    padding: 0 20px;
    color: #C4C4C4;
    display: grid;
    align-items: center;
    &:after {
      content: '';
      background: #A7AABC;
      height: 3px;
      width: 3px;
      display: block;
    }
  }
}