.tables-cert {
  display: block;
  position: relative;
  padding-top: 0;
  background-size: cover;
  color: #000000;
  font-size: rem(16px);

  &__title {
    margin-bottom: 50px;
  }
  .table-title {
    font-size: 22px;
    margin-bottom: rem(35px);
  }
  .table-important {
    display: block;
  }

  td, th {
    border:  solid #5F91C5;
    border-width: 0 1px 1px 0;
    vertical-align: top;
    @include lg-block {
      border-width: 0 1px 1px 1px;
    }
  }
  td {
    position: relative;
    padding: rem(30px) rem(20px);
  }
  .not-cert {
    color: #888888;
    background: #FFFFFF;
    border: 1px solid #888888;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 10px;
    display: flex;
    align-items: center;
    position: absolute;
    padding: 10px 20px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    left: 11px;
    bottom: 20px;
    width: 90%;
  }
  .not-cert-symbol {
    height: 28px;
    width: 52px;
    margin-right: 14px;
    background: url( /static/images/pages/certificate/tables-cert/svg/symbol.svg ) no-repeat center center;
    display: inline-block;
  }
  .not-cert-text {
    display: inline-block;
  }
  &__table {
    border:  solid #5F91C5;
    border-width: 1px 0 0 1px;
    font-size: 16px;
    color: #505050;
    min-width: 770px;
    //@include lg-block {
    //  border: 1px solid #5F91C5;
    //}
    tr {
      display: grid;
      grid-template-columns: 3fr 4fr 4fr;
      //@include lg-block {
      //  margin: 15px;
      //}
    }
    &_head{
      border-radius: 50px 5px 0 0;
      background: #014A94;
      width: 100%;
      font-weight: 500;
      font-size: 24px;
      color: #FFFFFF;
      padding: 29px 0;
    }
  }
  &__table-wrapper {
    //overflow-x: auto;
    margin-bottom: 20px;
  }
  .bg-grey {
    background: #F2F2F2;
    font-size: 18px;
    color: #000000;
  }
}

.simplebar-track.simplebar-horizontal {

  
  border-radius: 7px;
  @include sm-block {
    position: sticky;
    bottom: 11px;
    background-color: #014A94;
  }
}
.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}
.simplebar-scrollbar::before {
  background-color: #fff;
}