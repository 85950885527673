.unique-news {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    font-size: 14px;
  }
  .swiper-container {
    margin-bottom: 50px;
  }

  .swiper-slide, .swiper-slide-duplicate-active {

    &:before {
      content: '';
      background: rgba(255,255,255,0.6) no-repeat center center;
      height: 100%;
      width: 100%;
      position: absolute;
      transition: all 0.5s;
      opacity: 1;
    }
    &.swiper-slide-active:before, &.swiper-slide-duplicate-active:before {
      background: rgba(0,0,0,0.6) url(/static/images/pages/unique-news/svg/icon-magnifier.svg) no-repeat center center;
      opacity: 0;
    }


    &.swiper-slide-active:hover:before {
      opacity: 1;
    }
  }
  .fullscreen  {
     & .swiper-slide:before{
      display: none;
    }

  }

  .swiper-button-next, .swiper-button-prev {
    height: 100%;
    top: 22px;
  }
  .swiper-button-prev {
    left: 0;
    &:after {
      right: 10px;
    }
  }

  .swiper-button-next {
    right: 0;
    &:after {
      left: 10px;
    }
  }

  .fullscreen .swiper-button-next, .fullscreen .swiper-button-prev {
    display: block;
  }

  .img-main {
    background-repeat: no-repeat;
    background-position: center center;
    height: 300px;
    margin-bottom: 60px;
  }

  .swiper-container {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }



  .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
    background-size: cover;
    cursor: pointer;
  }

  .swiper-slide-active {
    opacity: 1;
  }

  .pager-container {
    margin-bottom: 75px;
  }

  .source {
    font-size: 14px;
    color: #828282;
    display: flex;
    margin-bottom: 80px;
    margin-top: 20px;
    @include xs-block {
      display: block;
    }
    &__ul-link {
      left: 70px;
      margin-left: 5px;
      @include xs-block {
        margin-top: 20px;
      }
    }

  }

  .close-button {
    display: none;
    height: 45px;
    width: 45px;
    color: #fff;
    position: fixed;
    top: 15px;
    right: 15px;
    font-size: 32px;
    text-align: center;
    cursor: pointer;
    z-index: 1009999;
    opacity: .75;
  }

  .close-button:before {
    content: '×';
  }

  .close-button:hover,
  .close-button:active {
    opacity: 1;
  }

  .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: #161418;
  }

  .fullscreen .swiper-slide {
    cursor: default;
    background-size: contain;
  }

  .fullscreen .close-button {
    display: block;
  }

  .fullscreen .gallery-top {
    height: 100%
  }

  .fullscreen .gallery-thumbs {
    background-color: #000;
    height: 20%;
    position: fixed;
    bottom: 0;
    width: 100%;
    opacity: 0;
    z-index: 10;
    transition: opacity .3s ease-in-out;
    transition-delay: .5s;
  }

  .fullscreen .gallery-thumbs:hover {
    opacity: 1;
    transition-delay: 0s;
  }
  &.is-active {
    z-index: 100;
  }

  iframe{
    width: 100% !important;
    max-width: 100%;
  }
  img{
    max-width: 100%;
    height: auto !important;
  }
  p{
    margin: 4px 0px;
  }
  a{
    text-decoration: none;
    border-bottom: 1px solid;
    &:hover{
      border-bottom: transparent;
    }
  }
  .unique-news__right-bar{
    ul,ol{
      list-style: square;
      margin: 5px 0px;
      li{
        align-items: center;
        display: flex;
        &:before{
          content: '';
          width: 10px;
          height: 1px;
          background: #6191C6;
          margin-right: 5px;
        }
      }
    }
    blockquote {
      padding-left: 20px;
      font-style: italic;
    }
    ol {

      margin-left: 20px;
      li {
        display: list-item;
        list-style-position: outside;
        list-style: decimal;
        &:before{
          content: none;
        }
      }
    }
  }
}
