.membership {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    font-size: 14px;
  }
  &__main-text {
    font-size: 24px;
    letter-spacing: 0.25px;
    margin-bottom: 50px;
  }
  &__important-text {
    color: #505050;
    letter-spacing: 0.1px;
    background: #E0E0E0;
    border-radius: 6px;
    padding: 25px 33px ;
    border-bottom: 5px solid #FE842B;
    margin-bottom: 75px;
    @include xs-block {
      margin: 0 -30px 30px -10px;
      padding: 20px;
      border-radius: 0;
    }
  }
  &__text {
    font-size: 16px;
    color: #505050;
  }
  &__benefits {
    margin-top: 60px;
  }
  &__benefits-hat {
    font-size: 18px;
    margin-bottom: 50px;
  }
  &__benefits-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 55px;
    @include md-block {
      grid-template-columns: 1fr;
    }
  }
  &__benefit {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 40px;
    align-items: center;
  }
  &__container-benefits {
    margin-top: 65px;
    margin-bottom: 50px;
    @include sm-block {
      margin-top: 30px;
    }
  }
  &__content-img-benefit {
    display: grid;
    justify-content: center;
  }
  &__important-link {
    color: #014A94;
    font-size: 16px;
    text-transform: lowercase;
    margin: 0 5px;
    &:after {
      background: #014A94;
    }
  }
  &__container-membership {
    padding: 90px 40px 65px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 50px 0 0 0;
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    margin-bottom: 75px;
    @include md-block {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    }
    @include xs-block {
      margin: 40px -30px 75px -10px;
      padding: 90px 20px 65px;
    }
  }
  &__card-front {
    background-color: #ffffff;
    padding: 35px 9px 9px;
    border-radius: 6px;
  }
  .container-card {
    position: relative;
    height: 400px;
    @include xs-block {
      height: 450px;
    }
  }
  .btn-white {
    padding: 20px 28px 17px ;
    @include xs-block {
      display: block;
      padding: 20px 15px 17px ;
      font-size: 10px;
      min-width: auto;
    }
  }

  &__container-contact {
    padding: 45px 35px;
    border-radius: 6px;
    background: #E0E0E0;
    border-bottom: 8px solid #FE842B;
    display: grid;
    grid-template-columns: 2fr 3fr 3fr;
    grid-column-gap: 40px;
    align-items: center;
    @include md-block {
      grid-row-gap: 20px;
      grid-template-columns: 1fr 3fr;
    }
    @include xs-block {
      grid-template-columns: 1fr;
      padding: 45px 15px;
    }
    .btn-blue {
      padding: 20px 10px;
    }
  }
  &__container-contact-img {
    @include md-block {
      grid-row: 1/3;
    }
    @include xs-block {
      grid-row: 1;
    }
  }

  .card {
    transition: .6s ease-in-out;
    backface-visibility: hidden;
    position: absolute;
    width:100%;
  }
  &__card-back {
    transform: rotateY(180deg);
    background: #ffffff;
    height: 400px;
    border-radius: 6px;
  }
  &__contact-img {
    border-radius: 50%;
    background-position: center center;
    background-size: contain;
    width: 150px;
    height: 150px;
    margin:auto;
    @include md-block {
      margin:0 0 10px;
    }
  }
  &__contact-title {
    font-size: rem(20px);
    margin-bottom: rem(25px);
  }
  &__contact-tel {
    font-size: rem(16px);
    color: #505050;
    &_bold {
      font-size: rem(14px);
      color: #000000;
    }
    &_blue {
      color: #014A94;
    }
  }
  .btn-blue {
    padding: rem(21px) 0 rem(17px);
  }
  &__contact-mail {
    font-size: rem(16px);
    color: #505050;
    .mail {
      font-size: rem(14px);
      color: #014A94;
      text-decoration: underline;
    }
  }
  //Вращение карточек
  //.container-card:hover &__card-front {
  //  transform: rotateY(180deg);
  //}
  //.container-card:hover &__card-back {
  //  transform: rotateY(0deg);
  //  z-index: 3;
  //}
  &__price-rub {
    font-size: 18px;
  }
  &__title-card {
    font-size: 32px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #014A94;
    margin-bottom: 40px;
    @include xs-block {
      font-size: 20px;
    }
  }
  &__price-card {
    background: #014A94;
    border-radius: 6px;
    padding: 40px 30px 50px;
    text-align: center;
  }
  &__price-card-text {
    color: #B9D5FF;
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
    @include xs-block {
      font-size: 12px;
    }
  }
  &__price-card-cost {
    color: #ffffff;
    font-size: 32px;
    margin-bottom: 30px;
    @include xs-block {
      font-size: 20px;
    }
  }

}