.representative {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    @include xs-block {
      width: 100%;
    }
    font-size: 14px;
  }
  &__wrap-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;

    @include sm-block {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 0;
    }
  }
  &__wrap-cards.is-hidden {
    display: none;
  }
  &__wrap-card {
    background: #ECEFF5;
    border-radius: 6px;
    padding: rem(30px) rem(30px) rem(40px);
    display: grid;
    grid-template-rows: 330px 80px 60px 110px 25px ;
    @include lg-block {
      grid-template-rows: 330px 75px 60px 80px 25px ;
    }
    @include sm-block {
      grid-template-rows: 280px 75px auto auto 25px;
      padding: rem(30px) rem(15px) rem(40px);
    }
  }
  .img {
    height: 285px;
    width: 285px;
    margin: 0 auto;
    @include lg-block {
      margin: auto;
    }
    @include sm-block {
      height: 225px;
      width: 225px;
    }
  }
  .img-detail {
    @include sm-block {
      margin: auto;
      height: 225px;
      width: 225px;
    }
  }
  &__position {
    font-size: 14px;
    font-weight: 700;
    @include xs-block {
      margin-bottom: 15px;
    }
  }
  &__description {
    font-size: 16px;
    color: #737687;
  }
  &__commission-main-title {
    margin: 120px auto 40px;
    font-size: 32px;
    font-weight: 500;
    @include sm-block {
      margin: 80px auto 40px;
    }
  }
  &__commission-wrap {
    display: grid;
    grid-row-gap: 20px;
  }
  &__commission-block {
    border: 1px solid #737687;
    border-radius: 6px;
    padding: 30px 25px 25px;
  }
  &__commission-title {
    margin-bottom: rem(20px);
  }
  &__commission-description {
    font-size: 18px;
    color: #505050;
  }
  &__wrap-details {
    margin: 20px 0;
  }
  &__wrap-detail {
    display: none;
    position: relative;
    &_top {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-column-gap: 40px;
      margin-bottom: 45px;
      @include sm-block {
        grid-template-columns: 1fr;
      }
    }
    &_list {
      font-style: italic;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 40px;
      &.author {
        font-weight: 400;
      }
    }
    &_point {
      position: relative;
      padding-left: 25px;
      margin-bottom: 15px;
      color: #505050;
      &:after {
        content: '';
        position: absolute;
        background-color: #FE842B;
        width: 8px;
        height: 8px;
        left: 0;
        top: 6px;
        border-radius: 50%;
      }
    }
    &_title {
      font-size: 18px;
      margin-bottom: 20px;
      display: block;
    }
    &_text {
      font-size: 16px;
      margin-bottom: 35px;
    }
    &.is-active {
      display: block;
    }
  }
  &__arrow-back {
    position: absolute;
    top: -120px;
    right: 0;


    @include lg-block {
      top: -110px;
    }
    @include sm-block {
      top: -90px;
    }
  }
  &__detail-name {
    font-weight: 500;
    font-size: 32px;
    margin-top: 35px;
  }
  &__detail-position {
    font-weight: 700;
    font-size: 14px;
    margin-top: 35px;
  }

  &__detail-description {
    font-size: 16px;
    color: #737687;
    margin-top: 25px;
  }
}