.main-slider {
  display: block;
  position: relative;
  padding: 0 0 rem(80px);
  background-size: cover;
  color: #000000;
  font-size: rem(16px);
  @include sm-block {
    padding-left: rem(50px);
  }
  @include xs-block {
    padding-bottom: rem(0px);
  }
  &__main-title {
    color: #ffffff;
    font-weight: 700;
    line-height: 60px;
    @include col();
    @include size(9);
    @include shift-right(3);
    @include size-lg(12);
    @include shift-lg(0);
    margin-left: 0;
    @include lg-block{
      text-align: left;
    }

    .h3 {
      a {
        color: #ffffff;
      }
      @include xs-block{
        line-height: 30px;
        font-size: 20px;
      }
    }
  }
  //.title-link {
  //  transition: all 0.5s;
  //  &:hover {
  //    color: #014A94;
  //  }
  //}
  &__text-block {
    @include lg-block{
      margin-bottom: rem(50px);
    };
  }
  &__img-block {
    position: relative;
    width: 100%;
  }
  .bg-photo {
    min-height: rem(620px);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;
    @include lg-block{
      min-height: rem(720px);
    };
    @include sm-block{
      height: auto;
    };
    @include xs-block{
      padding-bottom: 260px;
    };
  }
  .bg-white {
    padding-top: rem(75px);
    @include lg-block{
      padding-top: rem(385px);
    };
    @include sm-block{
      padding-top: rem(305px);
    };
    @include xs-block{
      padding-top: rem(70px);
    };
  }
  &__wrapper-date {
    @include col();
    @include size(4);
    @include size-lg(6);
    @include size-sm(12);
    @include lg-block{
      text-align: left;
      grid-template-columns: 80px auto;
      grid-column-gap: 20px;
    }
    @include sm-block{
      grid-template-columns: 80px auto;
    }
    display: grid;
    grid-template-columns: auto auto;
    letter-spacing: 0.02em;
    align-items: center;
    margin-top: 50px;
    margin-left: 0;
  }
  &__logo {
    height: 75px;
    width: 75px;
    background: url( /static/images/pages/home/main-slider/svg/logo.svg ) no-repeat center center;
    @include sm-block{
      margin-left: 10px;
    }
  }
  &__text {
    @include col();
    @include size(5);
    @include size-lg(12);
    @include shift-right(7);
    @include shift-lg(0);
    @include lg-block{
      text-align: left;
    }
    color: #ffffff;
    font-weight: 500;
    font-size: 18px;
    margin-top: rem(60px);
    margin-left: 0;
  }
  .swiper-pagination-fraction {
    top: 18px;
    width: auto;
    right: 0;
    user-select: none;
  }
  .container-img {
    @include col();
    @include shift-left(5);
    @include size(7);
    @include shift-lg-left(0);
    @include size-lg(12);
    position: absolute;
    right: 0;
    bottom: -260px;
    @include xl-block {
      bottom: -210px;
    }
    @include lg-block {
      position: static;
    }

    @include sm-block{
      height: 100%;
      //width: 100%;
    };
    @include xs-block{
      height: 100%;
      width: auto;
    };
    .img {
      max-height: 480px;
      max-width: 100%;

    }
  }
  .swiper-nav {
    width: 260px;
    position: relative;
    @include col();
    @include size(3);
    @include size-lg(4);
    @include shift-lg-left(4);
    @include size-sm(6);
    @include shift-sm-left(3);
    @include shift-sm-right(3);
    @include size-xs(12);
    @include shift-xs-left(0);
    @include shift-xs-right(0);
    @include lg-block{
      order: 3;
    };
    @include sm-block{
      margin-top: 30px;
      height: 60px;
    };
    @include xs-block{}
  }
  &__date {
    color: #ffffff;
  }
  .wrapper-nav-mob {
    position: relative;
    z-index: 15;
  }
  .swiper-button-prev:after {
    content: '';
    width: 15px;
    height: 15px;
    display: block;
    transform-origin: center center;
    transform: translateY(0%) rotate(135deg);
    border-bottom: solid 2px #FE842B;
    border-right: solid 2px #FE842B;
    position: absolute;
    top: calc( 50% - 9px );
    margin-top: 0;
  }
  .swiper-button-prev, .swiper-button-next {
    content: '';
    width: 50px;
    height: 50px;
    top: 50%;
    background: transparent;
  }

  .wrapper-btn {
    @include col();
    @include size(2);
    @include size-lg(4);
    @include size-sm(12);
    //margin: rem(180px) 0 rem(10px);
    @include lg-block{
      order: 2;
    };
  }
  .swiper-button-next:after {
    content: '';
    width: 15px;
    height: 15px;
    display: block;
    transform-origin: center center;
    transform: translateY(0%) rotate(-45deg);
    border-bottom: solid 2px #FE842B;
    border-right: solid 2px #FE842B;
    position: absolute;
    top: calc( 50% - 9px );
    margin-top: 0;
  }
  .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 0;
  }
  .swiper-button-prev, .swiper-button-next {
    z-index: 12;
  }
  .swiper-container {
    @include col();
    @include size(12);
    @include size-lg(12);
    @include lg-block{
      order: 1;
      text-align: center;
      top: 150px;
      height: 915px;
    };
    @include sm-block{
      top: 0;
      margin-top: 50px;
    };
    @include xs-block{
      margin: 50px 0 0;
    };
    position: absolute;
    right: 0;
    top: 150px;
  }
  .btn-orange {
    display: block;
  }
  .swiper-slide {
    position: relative;
    padding-bottom: 250px;

  }

}