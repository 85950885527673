@media print {
  .header{
    *{
      width: auto;
    }
    .header-top{
      display: none;
    }
    .grid-row{
      justify-content: space-between;
    }
  }
  .left-bar{
    display: none;
  }
  .footer{
    display: none;
  }
  .header__wrapper-search{
    display: none;
  }
  .header__wrapper-login{
    display: none;
  }
  .header-nav{
    display: none;
    padding: 0px;
  }
  .grid-row{
    div[class$="__right-bar"]{
      width: 100%;
    }
  }
  .right-bar{
    display: none;
  }
  .title {
    margin-bottom: 35px;
  }
}
