.unique-review {
  display: block;
  position: relative;
  background-size: cover;
  color: #000000;
  padding-top: 0;
  font-size: rem(16px);
  &__right-bar {
    @include col();
    @include size(8);
    @include size-lg(12);
    @include lg-block {
      order: 1;
    }
    font-size: 14px;
  }

  .img-main {
    min-height: 100px;
    margin-bottom: 60px;
  }


}