.top-bar {
  @include col();
  @include size-lg(6);
  @include size-sm(12);
  display: none;
  @include lg-block {
    order: 0;
    display: block;
    margin-bottom: rem(45px);

  }
  .current-page{
    background-color: #FE842B;
    border-radius: 6px;
    padding: 20px 40px 12px 17px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    &:after {
      position: absolute;
      content: "";
      top: 25px;
      right: 15px;
      width: 0;
      height: 0;
      border: 6px solid #ffffff;
      border-color: #ffffff transparent transparent transparent;
    }

  }
  .is-open .current-page:after {
    transform: translateY(0%) rotate(-180deg);
    top: 20px;
  }
  .list {
    overflow: hidden;
    line-height: 0;
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
    padding-left: 6px;
    font-weight: 600;
    color: #908385;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    max-height: rem(160px);
    overflow-y: scroll;
    background: #ffffff;
    width:100%;
    position: absolute;
    border: 1px solid #FE842B;
    border-radius: 6px;
  }
  &__mobile-menu {
    position: relative;
    z-index: 1;
  }
  .is-open .list {
    line-height: 50px;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
  .link {
    color: #5F91C5;
    font-weight: 400;
    text-transform: uppercase;
    &.selected {
      padding: 13px 10px;
      border: 1px solid #FE842B;
      color: #FE842B;
      border-radius: 6px;
      font-weight: 700;
    }
  }

  .mobile-menu-section{
    .sub-menu-mobile-point{
      margin-left: 20px;
      line-height: 1.1;
      a{
        font-weight: 400;
        line-height: 1.2;
        padding: 5px 0px;
        display: block;
      }
    }
  }
}
